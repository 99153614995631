import React, { FC } from 'react';
import Modal from '@material-ui/core/Modal';
import Icon from '../../atoms/BrandIcon';
import { useScreen } from '../../../common/hooks/useScreen';

import styles from './successErrorModale.module.css';
import { InspireButton } from '../../atoms/button';
import SuccessNotification from './notification/SuccessNotification';
import ErrorNotification from './notification/ErrorNotification';
import { TErrorCodeModel } from '../../../@generated/webExpApi';
import Notification from './notification';
import classnames from 'classnames';

export enum Icons {
    WARNING = 'warning',
    CLOSE = 'close',
}

export interface IClaimModal {
    open: boolean;
    onClose?: () => void;
    closeButtonText?: string;
    isSuccess: boolean;
    title?: string;
    description: string;
    errorCode?: TErrorCodeModel;
    icon?: Icons;
    modalStyles?: string;
    buttonRowStyles?: string;
    buttonStyles?: string;
    onButtonClick?: () => void;
}

const SuccessErrorModal: FC<IClaimModal> = ({
    open,
    onClose,
    closeButtonText,
    isSuccess,
    title,
    description,
    errorCode,
    icon,
    modalStyles,
    buttonRowStyles,
    buttonStyles,
    onButtonClick,
}) => {
    const { isDesktop } = useScreen();

    return (
        <Modal open={open} className={styles.modal} onClose={onClose}>
            <div className={classnames(styles.content, modalStyles)}>
                <button aria-label="Close Icon" className={styles.closeButton} onClick={onClose}>
                    <Icon icon="action-close" size={isDesktop ? 'm' : 's'} className={styles.closeIcon} />
                </button>
                <Notification>
                    {isSuccess ? (
                        <SuccessNotification title={title} description={description} />
                    ) : (
                        <ErrorNotification title={title} code={errorCode} description={description} icon={icon} />
                    )}
                </Notification>
                <div className={classnames(styles.buttonRow, buttonRowStyles)}>
                    <InspireButton
                        onClick={onButtonClick ? onButtonClick : onClose}
                        text={closeButtonText || 'Close'}
                        className={classnames(styles.closeBtn, buttonStyles)}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default SuccessErrorModal;

import { IOrderModel, OrderStatusEnumModel } from '../../../@generated/webExpApi';

/**
 * @method isActiveOrder
 * @param {IOrderModel} order order item
 * @author Andrei Shubich <ashubich@inspirebrands.com>
 * @added 2023-15-03
 * @version 1.0
 * @returns {boolean} returns true if orderStatus is Received, PreparingFood or ReadyForPickup
 */
export const isActiveOrder = (order: IOrderModel) => {
    return [
        OrderStatusEnumModel.Received,
        OrderStatusEnumModel.PreparingFood,
        OrderStatusEnumModel.ReadyForPickup,
    ].includes(order?.orderStatus);
};

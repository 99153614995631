import React, { useCallback } from 'react';
import styles from './findStall.module.css';
import { InspireButton } from '../../../../atoms/button';
import CheckInModal from '../../common/modal';
import ModalFooter from '../../common/modalFooter';
import { CheckInModalProps, CheckInModalsTypes } from '../../types';
import classNames from 'classnames';
import BrandIcon from '../../../../atoms/BrandIcon';

function FindStallModel({ open, modalsController }: CheckInModalProps) {
    const handleClose = useCallback(() => {
        modalsController.close(CheckInModalsTypes.FIND_STALL);
    }, []);

    return (
        <CheckInModal open={open} onClose={handleClose}>
            <div className={styles.container}>
                <BrandIcon icon={'brand-stall-number'} size="huge" paths={6} className={styles.icon} />
                <h3 className={classNames('t-header-card-title', styles.title)}>Find Stall Number</h3>
                <p className="t-paragraph">
                    You can find your stall number on the bottom corner of the digital menu screen.
                </p>
                <ModalFooter>
                    <InspireButton onClick={handleClose}>Got It</InspireButton>
                </ModalFooter>
            </div>
        </CheckInModal>
    );
}

export default FindStallModel;

import React from 'react';
import Icon from '../../../atoms/BrandIcon';
import classnames from 'classnames';
import styles from './notification.module.css';
import { CONTACT_CUSTOMER_SERVICE_EMAIL, ERROR_LABEL } from './constants';
import { TErrorCodeModel } from '../../../../@generated/webExpApi';
import { InspireSimpleLink } from '../../../atoms/link/simpleLink';
import getBrandInfo from '../../../../lib/brandInfo';
import { Icons } from '../successErrorModal';
import { CONSTANTS } from '../../../../constants/en/commons';
import { formatStringWithDynamicValue } from '../../../../common/helpers/formatStringWithDynamicValue';
import { mergeDynamicValueAndComponent } from '../../../../common/helpers/mergeStringAndComponent';

interface IErrorNotification {
    title?: string;
    description: string;
    code?: TErrorCodeModel;
    icon?: Icons;
}

const ErrorNotification = ({ title, description, code, icon }: IErrorNotification): JSX.Element => {
    return (
        <>
            {getErrorIcon(icon)}
            <span className={classnames('t-header-h3', styles.title)}>{title || ERROR_LABEL}</span>
            <p className={classnames('t-paragraph', styles.notificationDescription, styles.errorDescription)}>
                <span>{getErrorMessage(description, code)}</span>
            </p>
        </>
    );
};

const errorMessageUi = (content, message) => {
    const contentData = formatStringWithDynamicValue(content, {
        '{message}': message,
    });
    return mergeDynamicValueAndComponent({ stringValue: contentData, dynamicElements: { '{link}': getLink() } });
};

const getErrorMessage = (errorMessage: string, errorCode: TErrorCodeModel) => {
    const { CONTACT_CUSTOMER_SERVICE, CONTACT_US, SOMETHING_WHEN_WRONG } = CONSTANTS;

    if (errorMessage) {
        if (shouldShowEmail(errorCode)) {
            const message = removeDotFromMessage(errorMessage);
            if (errorCode === TErrorCodeModel.Generic) {
                return errorMessageUi(CONTACT_CUSTOMER_SERVICE, message);
            }
            return errorMessageUi(CONTACT_US, message);
        }
        return <>{errorMessage}</>;
    }
    return errorMessageUi(SOMETHING_WHEN_WRONG, '');
};

const removeDotFromMessage = (errorMessage: string) => {
    if (errorMessage && errorMessage[errorMessage.length - 1] === '.') {
        return errorMessage.slice(0, -1);
    }
    return errorMessage;
};

const getLink = () => {
    return (
        <InspireSimpleLink link={`mailto:${CONTACT_CUSTOMER_SERVICE_EMAIL}`} ariaLabel={'support email'}>
            {CONTACT_CUSTOMER_SERVICE_EMAIL}
        </InspireSimpleLink>
    );
};

const shouldShowEmail = (errorCode: TErrorCodeModel) => {
    return (
        errorCode &&
        (errorCode === TErrorCodeModel.AlreadyAwarded ||
            errorCode === TErrorCodeModel.InvalidPastDateBeforeEnrollment ||
            errorCode === TErrorCodeModel.Generic)
    );
};

const getErrorIcon = (errorIcon: Icons) => {
    const { brandId } = getBrandInfo();

    if (errorIcon && errorIcon === Icons.WARNING) {
        return (
            <div>
                <img
                    aria-label="warning icon"
                    src={`/brands/${brandId.toLowerCase()}/warning.svg`}
                    className={styles.warning}
                    alt="warning icon"
                />
            </div>
        );
    }
    return (
        <Icon ariaLabel="close icon" icon="action-close" size="m" className={classnames(styles.icon, styles.error)} />
    );
};

export default ErrorNotification;

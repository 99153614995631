import {
    IVisitRegisterRequestModel,
    IVisitRegisterResponseModel,
    RegisterVisitRequest,
    WebExperienceApi,
} from '../../@generated/webExpApi';
import createErrorWrapper from './createErrorWrapper';

const expApi = new WebExperienceApi();

const visitApi = createErrorWrapper<IVisitRegisterResponseModel, RegisterVisitRequest>(
    'registerStall',
    expApi.registerVisit.bind(expApi)
);

export const registerVisit = (params: IVisitRegisterRequestModel) => {
    return visitApi({ iVisitRegisterRequestModel: params });
};

import React from 'react';
import styles from './index.module.css';
import classnames from 'classnames';

export interface ICounter {
    value: number | string;
    ariaLabel?: string;
    counterClassName?: string;
    dataTestId?: string;
}

const Counter = ({ value, counterClassName, ariaLabel, dataTestId, ...props }: ICounter): JSX.Element => {
    return (
        <span
            {...props}
            className={classnames('t-paragraph-hint-strong', styles.counter, {
                [counterClassName]: counterClassName,
            })}
            aria-label={ariaLabel}
            data-testid={dataTestId}
        >
            {value}
        </span>
    );
};

export default Counter;

import React from 'react';
import Button from '@material-ui/core/Button';

import styles from './closeButton.module.css';
import BrandIcon from '../BrandIcon';
import { CONSTANTS } from '../../../constants/en/commons';

interface ICloseButtonProps {
    onClick: () => void;
    dataTestId?: string;
}

const CloseButton = ({ onClick, dataTestId }: ICloseButtonProps): JSX.Element => {
    const { CLOSE } = CONSTANTS;
    return (
        <Button
            fullWidth
            variant="contained"
            classes={{ root: styles.closeButton }}
            startIcon={<BrandIcon className={styles.closeButtonIcon} icon="action-close" />}
            onClick={onClick}
            data-testid={dataTestId}
        >
            <span className={styles.buttonText}>{CLOSE}</span>
        </Button>
    );
};

export { CloseButton };

import React, { useCallback } from 'react';
import dynamic from 'next/dynamic';
import { IAlertBannerFields } from '../../../@generated/@types/contentful';

import { useDismissedAlertBanners } from '../../../redux/hooks/index';
import styles from './alertBanners.module.css';
import { InspireCmsEntryCollection } from '../../../common/types';

export default function AlertBanners(props: {
    alertBanners: InspireCmsEntryCollection<IAlertBannerFields>;
    isPreviewMode?: boolean;
}): JSX.Element | null {
    const { alertBanners, isPreviewMode } = props;

    const AlertBanner = dynamic(() => import('./alertBanner'));

    const previewModeBanner = (
        <AlertBanner
            bannerId="previewBanner"
            text="You are in Preview Mode! To exit from Preview Mode close this banner."
            backgroundColor="var(--col--light)"
            textColor="red"
            isDismissible
            onCloseClick={() => window.location.replace('/api/exit-preview')}
            key="previewBanner"
        />
    );

    if (!alertBanners && isPreviewMode) {
        return previewModeBanner;
    }

    if (!alertBanners) return null;

    // TODO Fix usage of hooks
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dismissedAlertBanners = useDismissedAlertBanners();
    // TODO Fix usage of hooks
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleCloseClick = useCallback(
        (bannerId) => {
            dismissedAlertBanners.actions.dismissBanner({
                bannerEntry: {
                    bannerId,
                },
            });
        },
        [dismissedAlertBanners.actions]
    );

    return (
        <div className={styles.alertBannersContainer}>
            {alertBanners.items &&
                alertBanners.items.map((alertBanner) => {
                    if (!alertBanner.fields) return null;

                    const {
                        link,
                        text,
                        backgroundColor,
                        textColor,
                        isDismissible,
                        showOnCheckout,
                    } = alertBanner.fields;

                    const bannerId = alertBanner.sys.id;

                    const hideBanner = dismissedAlertBanners.bannerEntries.some(
                        (banner) => banner.bannerId === bannerId
                    );

                    if (hideBanner) return null;

                    return (
                        <AlertBanner
                            bannerId={bannerId}
                            link={link}
                            text={text}
                            backgroundColor={backgroundColor}
                            textColor={textColor}
                            isDismissible={isDismissible}
                            showOnCheckout={showOnCheckout}
                            onCloseClick={handleCloseClick}
                            key={bannerId}
                        />
                    );
                })}
            {isPreviewMode && previewModeBanner}
        </div>
    );
}

import React from 'react';
import classnames from 'classnames';
import { useLogout } from '../../../common/hooks/useLogout';
import styles from './logoutButton.module.css';
import { useBag } from '../../../redux/hooks';
import { CONSTANTS } from '../../../constants/en/commons';

interface ILogoutButton {
    className?: string;
}

const LogoutButton = (props: ILogoutButton): JSX.Element => {
    const { LOGOUT } = CONSTANTS;
    const { logoutAndClearCookies } = useLogout();
    const { className } = props;
    const bag = useBag();
    const handleLogout = (e) => {
        e.preventDefault();
        bag.actions.removeDealFromBag();
        logoutAndClearCookies();
    };

    return (
        <button
            data-gtm-id="accountLogout"
            className={classnames(className, styles.logoutButton)}
            onClick={handleLogout}
        >
            {LOGOUT}
        </button>
    );
};

export { LogoutButton };

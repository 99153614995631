import React, { useCallback } from 'react';
import styles from './connectionIssues.module.css';
import { InspireButton } from '../../../../atoms/button';
import CheckInModal from '../../common/modal';
import ModalFooter from '../../common/modalFooter';
import { CheckInModalProps, CheckInModalsTypes } from '../../types';
import classNames from 'classnames';
import getBrandInfo from '../../../../../lib/brandInfo';

function ConnectionIssuesModel({ open, modalsController }: CheckInModalProps) {
    const { brandId } = getBrandInfo();
    const brandIdLowercase = brandId.toLowerCase();

    const handleClose = useCallback(() => {
        modalsController.close(CheckInModalsTypes.CONNECT_ISSUES);
    }, []);

    return (
        <CheckInModal open={open} onClose={handleClose}>
            <div className={styles.container}>
                <div>
                    <span className={classNames('t-subheader', styles.ohno)}>Oh No...</span>
                    <h3 className={classNames('t-header-h1', styles.title)}>Connect Issues</h3>
                </div>
                <div className={styles.imageContainer}>
                    <img src={`/brands/${brandIdLowercase}/connection-error.svg`} alt="" />
                </div>
                <p className={classNames('t-paragraph', styles.description)}>
                    We are having trouble connecting you. Please try again.
                </p>
                <ModalFooter>
                    <InspireButton onClick={handleClose}>Got It</InspireButton>
                </ModalFooter>
            </div>
        </CheckInModal>
    );
}

export default ConnectionIssuesModel;

import React, { useMemo } from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import Icon from '../../../atoms/BrandIcon';
import StartOrderButton from '../../../atoms/startOrderButton/startOrderButton';
import CloseButton from '../../../atoms/CloseButton';
import {
    IDocumentLink,
    IExternalLink,
    IMenuCategoryLink,
    IPageLink,
    IPhoneNumberLink,
    IProduct,
} from '../../../../@generated/@types/contentful';
import NavigationLink from '../navigationLink';
import {
    useAccount,
    useBag,
    useLoyalty,
    useNotifications,
    useOrderLocation,
    useRewards,
} from '../../../../redux/hooks';
import { CheckLocation } from '../navigationUtils';
import Counter from '../../../atoms/counter';
import {
    SHOULD_SHOW_NAV_MENU_ACTIONS_WITHOUT_LOCATION,
    SHOULD_SHOW_NAV_MENU_SIGN_IN,
    SHOULD_SHOW_NAV_MENU_ACCOUNT_INFO,
    SHOULD_SHOW_NAV_MENU_BAG_COUNTER,
    NAVIGATION_MENU_BAG_TEXT,
    SHOULD_HIDE_START_ORDER_BTN_WHEN_ITEMS_IN_BAG,
    SHOULD_CHANGE_COLOR,
} from '../constants';
import { NAV_LINKS } from '../navigationLinks.constants';
import getBrandInfo, { BRANDS } from '../../../../lib/brandInfo';
import { useAuth0 } from '@auth0/auth0-react';
import SignInLabel from '../signIn/signInLabel';
import { useLogout } from '../../../../common/hooks/useLogout';
import useBagAvailableItemsCounter from '../../../../common/hooks/useBagAvailableItemsCounter';
import { useAppDispatch } from '../../../../redux/store';
import { GTM_HAMBURGER_MENU_OPTION_SELECT } from '../../../../common/services/gtmService/constants';

import styles from './navigationMenu.module.css';

interface INavigationMenuProps {
    rewardsCount: number;
    open?: boolean;
    links?: (IExternalLink | IMenuCategoryLink | IPageLink | IProduct | IPhoneNumberLink | IDocumentLink)[];
    onClose: () => void;
}

const NavigationMenu: React.FC<INavigationMenuProps> = ({ rewardsCount, open, links, onClose }) => {
    const dispatch = useAppDispatch();
    const { brandId } = getBrandInfo();
    const { loyalty } = useLoyalty();
    const bag = useBag();
    const { getApplicableOffers } = useRewards();
    const router = useRouter();
    const { loginWithRedirect, isAuthenticated } = useAuth0();
    const {
        actions: { enqueueError },
    } = useNotifications();
    const { logoutAndClearCookies } = useLogout();

    const handleLogoutClick = () => {
        bag.actions.removeDealFromBag();
        logoutAndClearCookies();
    };

    const { actions } = useBag();
    const toggleBagOpen = () => actions.toggleIsOpen({ isOpen: true });

    const handleAccountClick = () => {
        router.push('/account/');
    };

    const handleSignInClick = () => {
        try {
            loginWithRedirect({ appState: { target: window.location.pathname }, isIDP: true });
        } catch ({ message }) {
            enqueueError({ message });
        }
    };
    const location = CheckLocation();

    const bagAvailableCount = useBagAvailableItemsCounter();
    const bagItemsCount = bagAvailableCount < 10 ? bagAvailableCount : '9+';
    const hasAvailableItemsInBag = bagAvailableCount > 0;

    const { account } = useAccount();
    const { currentLocation } = useOrderLocation();

    const isBonus = useMemo(() => {
        if (brandId === BRANDS.bww.brandId) return loyalty?.pointsBalance || rewardsCount;

        return getApplicableOffers(currentLocation?.id)?.length;
    }, [brandId, loyalty?.pointsBalance, rewardsCount, getApplicableOffers, currentLocation?.id]);

    const handleOnClose = (linkName: string) => {
        if (linkName) {
            dispatch({ type: GTM_HAMBURGER_MENU_OPTION_SELECT, payload: linkName });
        }

        onClose();
    };

    return (
        <Modal open={open} hideBackdrop>
            <div className={styles.navigationMenu}>
                <div>
                    <CloseButton onClick={onClose} dataTestId="navigation-menu-close" />
                    {isAuthenticated && SHOULD_SHOW_NAV_MENU_ACCOUNT_INFO && (
                        <>
                            <div className={styles.navMenuTopSeparator}></div>
                            <div className={styles.signInWrapper}>
                                <SignInLabel
                                    isAuthenticated={isAuthenticated}
                                    signInText={`Hi ${account?.firstName}`}
                                    showSignInIcon={isAuthenticated}
                                    isBonus={!!isBonus}
                                    signInTextClassName={styles.signInText}
                                    signInIconClassName={styles.signInIcon}
                                />
                                <span
                                    className={styles.logout}
                                    onClick={handleLogoutClick}
                                    data-testid="navigation-menu-logout"
                                >
                                    LOGOUT
                                </span>
                            </div>
                        </>
                    )}
                </div>
                <div className={styles.menuLinkContainer}>
                    {links &&
                        links.map((link) => {
                            const nameInUrl = 'nameInUrl' in link.fields ? link.fields.nameInUrl : null;

                            return (
                                <div className={styles.linkWrapper} key={link.sys.id}>
                                    <NavigationLink
                                        exact
                                        key={link.sys.id}
                                        link={link}
                                        onClick={() => handleOnClose(link.fields.name)}
                                        inLowerCase
                                        labelClassName={styles.linkLabel}
                                        activeClassName={styles.linkActive}
                                        dataTestId={`navigation-menu-link-${link.fields.name.toLowerCase()}`}
                                    />
                                    {rewardsCount > 0 && nameInUrl === NAV_LINKS.DEALS && (
                                        <Counter
                                            counterClassName={styles.navigationMenuLinkCounter}
                                            value={rewardsCount}
                                        />
                                    )}
                                </div>
                            );
                        })}
                </div>
                {(SHOULD_SHOW_NAV_MENU_ACTIONS_WITHOUT_LOCATION || location) && (
                    <>
                        <div className={styles.menuActionContainer}>
                            <Button
                                classes={{ root: styles.menuActionButton }}
                                onClick={toggleBagOpen}
                                data-testid="navigation-menu-bag"
                            >
                                <div className={styles.navigationMenuBagWrapper}>
                                    <Icon
                                        icon="navigation-bag"
                                        className={styles.menuActionBagIcon}
                                        variant={hasAvailableItemsInBag && SHOULD_CHANGE_COLOR ? 'colorful' : undefined}
                                    />
                                    {SHOULD_SHOW_NAV_MENU_BAG_COUNTER && hasAvailableItemsInBag && (
                                        <Counter
                                            ariaLabel="Bag counter"
                                            counterClassName={styles.bagCounter}
                                            value={bagItemsCount}
                                        />
                                    )}
                                </div>
                                <span
                                    className={classnames(styles.menuActionButtonText, {
                                        [styles.menuActionButtonTextIndent]: hasAvailableItemsInBag,
                                    })}
                                >
                                    {NAVIGATION_MENU_BAG_TEXT}
                                </span>
                            </Button>
                            {SHOULD_SHOW_NAV_MENU_SIGN_IN && (
                                <Button
                                    classes={{ root: styles.menuActionButton }}
                                    onClick={isAuthenticated ? handleAccountClick : handleSignInClick}
                                    data-testid="navigation-menu-account"
                                >
                                    <SignInLabel
                                        isAuthenticated={isAuthenticated}
                                        signInText="My Account"
                                        showSignInIcon={isAuthenticated}
                                        isBonus={!!isBonus}
                                        signInTextClassName={styles.menuActionButtonText}
                                        signInIconClassName={styles.signInIcon}
                                    />
                                </Button>
                            )}
                        </div>
                        {!(SHOULD_HIDE_START_ORDER_BTN_WHEN_ITEMS_IN_BAG && bagItemsCount) && (
                            <StartOrderButton
                                className={styles.startOrderButton}
                                onClick={onClose}
                                dataTestId="navigation-menu-start-order-button"
                            />
                        )}
                    </>
                )}
            </div>
        </Modal>
    );
};

export default NavigationMenu;

import { useOrderLocation } from '../../redux/hooks';
import { OrderLocationMethod } from '../../redux/orderLocation';
import { useLocationSearchUrl } from './useLocationSearchUrl';

const useStartAnOrderLink = () => {
    const { method } = useOrderLocation();
    const { locationSearchUrl } = useLocationSearchUrl();

    return method === OrderLocationMethod.NOT_SELECTED ? locationSearchUrl : '/menu';
};

export default useStartAnOrderLink;

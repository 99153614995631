import React from 'react';
import classNames from 'classnames';
import styles from './modalFooter.module.css';

interface ModalFooter {
    className?: string;
    children: JSX.Element;
}

function ModalFooter({ className, children }: ModalFooter) {
    return <div className={classNames(styles.container, className)}>{children}</div>;
}

export default ModalFooter;

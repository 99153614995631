import React, { ReactEventHandler } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { FULL_WIDTH } from './constants';
import useStartAnOrderLink from '../../../common/hooks/useStartAnOrderLink';

import styles from './startOrderButton.module.css';
import { InspireButton } from '../button';
import { CONSTANTS } from '../../../constants/en/commons';

interface IStartOrderButtonProps {
    onClick?: ReactEventHandler;
    className?: string;
    dataTestId?: string;
}

export default function StartOrderButton(props: IStartOrderButtonProps): JSX.Element {
    const { START_TO_ORDER } = CONSTANTS;
    const buttonClasses = classNames(styles.startOrderButton, props.className);
    const startAnOrderLink = useStartAnOrderLink();
    const router = useRouter();

    const handleClick = () => router.push(startAnOrderLink);

    return (
        <InspireButton
            className={buttonClasses}
            text={START_TO_ORDER}
            fullWidth={FULL_WIDTH}
            onClick={handleClick}
            dataTestId={props.dataTestId}
            gtmId="startOrder-CTA"
            id="start-order-btn"
        />
    );
}

import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { ILocationAddressModel, ILocationWithDetailsModel } from '../../../../../@generated/webExpApi';
import useWatchDistance from '../../../../../common/hooks/useWatchGeoDistance';
import { getLocationAddressString } from '../../../../../lib/locations';
import BrandIcon from '../../../../atoms/BrandIcon';
import { InspireButton } from '../../../../atoms/button';
import styles from './almostThere.module.css';
import ModalFooter from '../../common/modalFooter';
import { CheckInModalProps, CheckInModalsTypes } from '../../types';
import CheckInModal from '../../common/modal';
import { convertDistance, DistanceUnits } from '../../../../../common/helpers/convertDistance';
import { CHECKIN_DISTANCE_LIMIT } from '../../constants';
import usePickupLocationsSearch from '../../../../../common/hooks/usePickupLocationsSearch';
import { LoadingStatusEnum } from '../../../../../common/types';
import { Divider } from '../../../../atoms/divider';
import BrandLoader from '../../../../atoms/BrandLoader';
import { getTitleAndDescription, getWrongStoreFromLocationResult } from './almostThere.helpers';
import { GTM_CHECK_IN_WRONG_LOCATION } from '../../../../../common/services/gtmService/constants';
import { useAppDispatch } from '../../../../../redux/store';
import { useBrazeIntegration } from '../../../../../common/hooks/braze/useBrazeIntegration';
import { BrazeEventKey } from '../../../../../common/hooks/braze/types';

interface AlmostThereProps extends CheckInModalProps {
    location: ILocationWithDetailsModel;
    onCheckInClick: () => void;
    checkInOrderId?: string;
}

function AlmostThereModal({ location, modalsController, open, onCheckInClick, checkInOrderId }: AlmostThereProps) {
    const address: ILocationAddressModel = location?.contactDetails?.address;
    const dispatch = useAppDispatch();
    const { setCustomEvent } = useBrazeIntegration();

    const { distance, deviceCoords } = useWatchDistance(location?.details?.latitude, location?.details?.longitude);

    const { searchLocationsByCoordinates, locationsSearchResult, locationsSearchStatus } = usePickupLocationsSearch();
    const wrongStore = getWrongStoreFromLocationResult(locationsSearchResult, location?.id);
    const { distance: distanceToTheWrongStore, loading: distanceToTheWrongStoreLoading } = useWatchDistance(
        wrongStore?.details?.latitude,
        wrongStore?.details?.longitude
    );

    const formattedDistance = distance?.toFixed(1);
    const formattedAddress = useMemo(() => getLocationAddressString(location, false), [location]);

    const distanceInFoots = convertDistance(distance, DistanceUnits.MILES, DistanceUnits.FOOTS);
    const formattedDistanceInFoots = distanceInFoots?.toFixed(1);

    const isOnStoreTerritory = Number.isFinite(distance) && distance < CHECKIN_DISTANCE_LIMIT;
    const isOnWrongStoreTerritory =
        !isOnStoreTerritory &&
        Number.isFinite(distanceToTheWrongStore) &&
        distanceToTheWrongStore < CHECKIN_DISTANCE_LIMIT;

    // loading status of location search to check whether user on the wrong store territory
    const isLoading =
        !isOnStoreTerritory &&
        ([LoadingStatusEnum.Idle, LoadingStatusEnum.Loading].includes(locationsSearchStatus) ||
            distanceToTheWrongStoreLoading);

    const { title, description } = useMemo(() => getTitleAndDescription(isOnStoreTerritory, isOnWrongStoreTerritory), [
        isOnStoreTerritory,
        isOnWrongStoreTerritory,
    ]);

    const distanceString = isOnStoreTerritory ? `${formattedDistanceInFoots} ft away` : `${formattedDistance} mi away`;

    useEffect(() => {
        if (!isOnStoreTerritory && locationsSearchStatus === LoadingStatusEnum.Idle && deviceCoords) {
            searchLocationsByCoordinates(deviceCoords);
        }
    }, [deviceCoords, isOnStoreTerritory, locationsSearchStatus, searchLocationsByCoordinates]);

    const handleGotIt = () => {
        modalsController.set(null);
        if (isOnWrongStoreTerritory) {
            dispatch({
                type: GTM_CHECK_IN_WRONG_LOCATION,
                payload: {
                    event_action: 'Got It',
                    checkIn_error: description,
                },
            });

            setCustomEvent(BrazeEventKey.SHOWS_UP_AT_WRONG_LOCATION, {
                currentStore: wrongStore?.id,
                orderStore: location?.id,
                orderId: checkInOrderId,
            });
        }
    };

    const handleTextMeALink = () => {
        modalsController.push(CheckInModalsTypes.TEXT_ME_A_LINK);
        if (isOnWrongStoreTerritory) {
            dispatch({
                type: GTM_CHECK_IN_WRONG_LOCATION,
                payload: {
                    event_action: 'Text Me a Link',
                    checkIn_error: description,
                },
            });

            setCustomEvent(BrazeEventKey.SHOWS_UP_AT_WRONG_LOCATION, {
                currentStore: wrongStore?.id,
                orderStore: location?.id,
                orderId: checkInOrderId,
            });
        }
    };

    return (
        <CheckInModal open={open} onClose={() => modalsController.set(null)}>
            <div className={styles.container}>
                <BrandIcon icon={'order-find-a-location'} size="huge" paths={4} />
                {isLoading ? (
                    <BrandLoader className={styles.loader} />
                ) : (
                    <>
                        <h3 className={classNames('t-header-card-title', styles.title)}>{title}</h3>
                        <p className="t-paragraph">{description}</p>
                    </>
                )}

                {isOnWrongStoreTerritory && <Divider orientation="horizontal" className={styles.divider} />}

                <div className={styles.locationDetails}>
                    <p className={classNames('t-paragraph-strong', styles.locationDetailsHeader)}>
                        Pickup Store Details
                    </p>
                    {address?.line1 && (
                        <p className={classNames('t-paragraph-strong', styles.locationDetailsAddress)}>
                            {address.line1}
                        </p>
                    )}
                    {address?.referencePoint && <p className="t-paragraph">{address.referencePoint}</p>}
                    {formattedAddress && <p className="t-paragraph">{formattedAddress}</p>}

                    {formattedDistance && (
                        <p className={classNames('t-paragraph-strong', styles.distance)}>{distanceString}</p>
                    )}
                </div>

                {!isOnStoreTerritory && (
                    <div className={styles.textMeALink}>
                        <p className="t-paragraph-strong">Want to check-in faster?</p>
                        <p className={classNames('t-paragraph', styles.textMeALinkDescription)}>
                            We can text you a link to this page, so you can check in using your phone when you arrive.
                        </p>
                        <InspireButton type="secondary" onClick={handleTextMeALink}>
                            Text Me a Link
                        </InspireButton>
                    </div>
                )}

                <ModalFooter>
                    {isOnStoreTerritory ? (
                        <InspireButton className={styles.btn} onClick={onCheckInClick}>
                            Let&#39;s Check In!
                        </InspireButton>
                    ) : (
                        <InspireButton className={styles.btn} onClick={handleGotIt}>
                            Got It
                        </InspireButton>
                    )}
                </ModalFooter>
            </div>
        </CheckInModal>
    );
}

export default AlmostThereModal;

import { useAuth0 } from '@auth0/auth0-react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import React from 'react';
import { useGlobalProps, useNotifications } from '../../../redux/hooks';
import { useAppDispatch } from '../../../redux/store';
import { GTM_BANNER_SIGN_UP } from '../../../common/services/gtmService/constants';
import styles from './signUpBanner.module.css';
import { isDealLinkEnabledInPencilBanner } from '../../../lib/getFeatureFlags';
import { IS_SHOW_SIGN_UP_BANNER } from './constants';

const SignUpBanner: React.FC = () => {
    const { signUpBanner } = useGlobalProps();
    const text = signUpBanner?.fields?.text;

    const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
    const {
        actions: { enqueueError },
    } = useNotifications();
    const router = useRouter();
    const dispatch = useAppDispatch();

    const handleSignUpClick = () => {
        if (!isAuthenticated) {
            try {
                const payload = {
                    category: 'Account',
                    action: 'Sign-Up Now',
                    label: 'Banner',
                };
                dispatch({
                    type: GTM_BANNER_SIGN_UP,
                    payload,
                });
                if (isDealLinkEnabledInPencilBanner()) {
                    router.push('/deals');
                } else {
                    loginWithRedirect({
                        appState: {
                            target: window.location.pathname,
                        },
                        screen_hint: 'signup',
                        flow: 'signup',
                        isIDP: true,
                    });
                }
            } catch ({ message }) {
                enqueueError({ message });
            }
            return;
        }
    };

    if (!IS_SHOW_SIGN_UP_BANNER || !text || isAuthenticated || isLoading) return null;

    return (
        <div className={classNames(styles.container, 't-paragraph-small-strong')} data-testid="sign-up-banner">
            {text}{' '}
            <span className={styles.signUp} onClick={handleSignUpClick} data-testid="sign-up-banner-sign-up-now">
                {isDealLinkEnabledInPencilBanner() ? 'More Info' : 'Sign up now'}
            </span>
        </div>
    );
};

export default SignUpBanner;

import { useMemo } from 'react';
import { locationTimeSlotsEnabled } from '../../lib/getFeatureFlags';
import { useOrderLocation } from '../../redux/hooks';
import { OrderLocationMethod } from '../../redux/orderLocation';

export const useUnavailableTimeSlots = (): { hasUnavailableTimeSlots: boolean } => {
    const isLocationTimeSlotsFeatureEnabled = locationTimeSlotsEnabled();
    const {
        isCurrentLocationOAAvailable,
        pickupLocationTimeSlots,
        deliveryLocationTimeSlots,
        method,
    } = useOrderLocation();

    const availableTimeSlots = useMemo(
        () => (method === OrderLocationMethod.DELIVERY ? deliveryLocationTimeSlots : pickupLocationTimeSlots),
        [deliveryLocationTimeSlots, pickupLocationTimeSlots, method]
    );

    const isTimeAvailable = !!Object.keys(availableTimeSlots?.[method.toLocaleLowerCase()]?.byDay || {}).length;

    const hasUnavailableTimeSlots =
        !!availableTimeSlots && isCurrentLocationOAAvailable && isLocationTimeSlotsFeatureEnabled && !isTimeAvailable;

    return { hasUnavailableTimeSlots };
};

/**
 * Method to convert dynamic value + static value in 1 string
 * @method formatStringWithDynamicValue
 * @param {string} stringValue normal string having static and dynamic value, example: Hello {data} {data1}
 * @param {Record<string, string|number>} dynamicValueData value which will be replace to the static key, example: {'{data}': 'test', '{data1}: 'developer'}
 * @returns {string} string that has been concatenated by a dynamic value, example: Hallo test developer
 * @example
 * formatStringWithDynamicValue(Hello {data} {data1}, {'{data}': 'test', '{data1}: 'developer'})
 */
const formatStringWithDynamicValue = (
    stringValue: string,
    dynamicValueData: { [key: string]: string | number }
): string =>
    Object.entries(dynamicValueData).reduce(
        (acc: string, [key, value]: [string, string | number]) => acc.replace(key, value.toString()),
        stringValue
    );

export { formatStringWithDynamicValue };

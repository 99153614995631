import classNames from 'classnames';
import React, { FC } from 'react';

import Icon from '../../atoms/BrandIcon';

import styles from './pageBanner.module.css';

interface IPageBannerProps {
    text: string;
}

const PageBanner: FC<IPageBannerProps> = ({ text }) => (
    <div role="banner" className={classNames('t-subheader-smaller', styles.container)}>
        <Icon icon="info-error" className={styles.errorIcon} />
        {text}
    </div>
);

export default PageBanner;

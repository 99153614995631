import React from 'react';
import classnames from 'classnames';
import SignInIcon from './signInIcon';
import Icon from '../../../../components/atoms/BrandIcon';
import { DEFAULT_SIGNIN_TEXT } from './constants';

import styles from './signIn.module.css';

const SignInLabel: React.FC<{
    handleSignInButtonClick?: () => void;
    isAuthenticated: boolean;
    signInTextRef?: React.MutableRefObject<HTMLSpanElement>;
    signInText: string;
    chevronIcon?: string;
    showRewardsInfo?: boolean;
    rewardsInfo?: string;
    showSignInIcon: boolean;
    showChevronIcon?: boolean;
    isBonus: boolean;
    gtmId?: string;
    signInTextClassName?: string;
    signInIconClassName?: string;
    profileAvatarUrl?: string;
}> = ({
    handleSignInButtonClick,
    isAuthenticated,
    signInTextRef,
    signInText,
    chevronIcon,
    showRewardsInfo,
    rewardsInfo,
    showSignInIcon,
    showChevronIcon,
    isBonus,
    gtmId,
    signInTextClassName,
    signInIconClassName,
    profileAvatarUrl,
}) => {
    const signInTextClassNames = classnames('truncate', styles.signInText, signInTextClassName);

    return (
        <div
            tabIndex={0}
            role="button"
            className={styles.signInButton}
            onKeyPress={handleSignInButtonClick}
            onClick={handleSignInButtonClick}
            data-gtm-id={gtmId}
        >
            {showSignInIcon && (
                <SignInIcon isBonus={isBonus} className={signInIconClassName} profileAvatarUrl={profileAvatarUrl} />
            )}
            <div className={styles.accountInfoContaner}>
                <>
                    <span className={signInTextClassNames} ref={signInTextRef}>
                        {isAuthenticated ? signInText : DEFAULT_SIGNIN_TEXT}
                    </span>
                    {showChevronIcon && <Icon className={styles.chevronIcon} icon={chevronIcon} size="tiny" />}
                </>
                {showRewardsInfo && (
                    <div className={classnames('t-paragraph-hint', styles.rewardsInfo)}>{rewardsInfo}</div>
                )}
            </div>
        </div>
    );
};

export default SignInLabel;

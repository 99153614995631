import React, { useMemo, useRef, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import classnames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { IUserAccountMenuFields } from '../../../../@generated/@types/contentful';
import Loader from '../../../atoms/Loader';
import NavigationDropdown from '../navigationDropdown/navigationDropdown';
import CloseButton from '../../../atoms/CloseButton';
import LogoutButton from '../../../atoms/logoutButton';
import styles from './signIn.module.css';
import Counter from '../../../atoms/counter';
import { useRewards, useLoyalty, useSelectedSell, useAccount, useOrderLocation } from '../../../../redux/hooks';
import useNotifications from '../../../../redux/hooks/useNotifications';
import { useDispatch } from 'react-redux';
import { GTM_USER_ID } from '../../../../common/services/gtmService/constants';
import { InspireCmsEntry } from '../../../../common/types';
import { isEpsilonRewardsOn } from '../../../../lib/getFeatureFlags';
import SuccessErrorModal from '../../../molecules/successErrorModal';
import getBrandInfo, { BRANDS } from '../../../../lib/brandInfo';
import useNavigationLinks from '../../../../common/hooks/useNavigationLinks';
import { useScreen } from '../../../../common/hooks/useScreen';
import SignInLabel from './signInLabel';
import SignInIcon from './signInIcon';

const SignIn = (props: {
    navRef?: React.MutableRefObject<HTMLElement>;
    userAccountMenu?: InspireCmsEntry<IUserAccountMenuFields>;
    signInTextClassName?: string;
    signInIconClassName?: string;
    hideDivider?: boolean;
}): JSX.Element => {
    const { hideDivider = false } = props;
    const { brandId } = getBrandInfo();
    const { loginWithRedirect, isAuthenticated, user, isLoading } = useAuth0();
    const { isDesktop } = useScreen();
    const signInTextRef = useRef<HTMLSpanElement>(null);
    const { pathname, query, push } = useRouter();
    const [isOpenVerificationModal, setOpenVerificationModal] = useState(true);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const chevronIcon = isDropdownOpen ? 'direction-up' : 'direction-down';
    const { account } = useAccount();
    const dispatch = useDispatch();
    const userAgent = typeof window !== 'undefined' && navigator?.userAgent;
    const { correlationId } = useSelectedSell();

    useEffect(() => {
        if (account || correlationId) {
            dispatch({
                type: GTM_USER_ID,
                payload: {
                    userID: account ? account.idpCustomerId : correlationId,
                    isLoggedIn: isAuthenticated,
                    userAgent,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, correlationId]);

    const { totalCount: rewardsCount, getApplicableOffers } = useRewards();
    const { loyalty, error: loyaltyError } = useLoyalty();
    const { currentLocation } = useOrderLocation();
    const isShowRewardsInfo = account && !loyaltyError && isEpsilonRewardsOn();

    const rewardsInfo = useMemo(() => {
        const infoItems: string[] = [];

        if (loyalty?.pointsBalance) {
            infoItems.push(`${loyalty?.pointsBalance} pts`);
        }

        if (rewardsCount) {
            infoItems.push(`${rewardsCount} ${rewardsCount > 1 ? 'rewards' : 'reward'}`);
        }

        return infoItems.join('  |  ');
    }, [rewardsCount, loyalty?.pointsBalance]);

    const isBonus = useMemo(() => {
        if (brandId === BRANDS.bww.brandId) return loyalty?.pointsBalance || rewardsCount;

        return getApplicableOffers(currentLocation?.id)?.length;
    }, [brandId, loyalty?.pointsBalance, rewardsCount, currentLocation?.id, getApplicableOffers]);

    const {
        actions: { enqueueError },
    } = useNotifications();

    const { navigationLinks } = useNavigationLinks(props.userAccountMenu);

    const isAccountLoading = isLoading || (user && !account);
    if (isAccountLoading) {
        return <Loader size={20} className={styles.signInLoading} />;
    }
    const handleSignInButtonClick = () => {
        if (!isAuthenticated) {
            try {
                const isDealLink = window.location.pathname === '/deals/';
                loginWithRedirect({
                    appState: {
                        target: isDealLink ? '/account/deals' : `${window.location.pathname}${window.location.search}`,
                    },
                    isIDP: true,
                });
            } catch ({ message }) {
                enqueueError({ message });
            }
        } else {
            setIsDropdownOpen(!isDropdownOpen);
        }
    };

    const handleNavigationDropdownClose = () => setIsDropdownOpen(false);

    const getSignInText = (name: string) => `Hi ${name}`;
    const onCloseEmailVerificationModal = () => {
        setOpenVerificationModal(false);
    };
    const redirectRewardsPage = () => {
        push(`${location.origin}/rewards`);
        setOpenVerificationModal(false);
    };
    const hasQueryEmailVerification = query && Object.keys(query).includes('isEmailVerification');

    return (
        <div className={styles.signInContainer}>
            <SignInLabel
                handleSignInButtonClick={handleSignInButtonClick}
                isAuthenticated={isAuthenticated}
                signInTextRef={signInTextRef}
                signInText={getSignInText(account?.firstName)}
                chevronIcon={chevronIcon}
                showRewardsInfo={isShowRewardsInfo && isDesktop}
                rewardsInfo={rewardsInfo}
                showSignInIcon={isAuthenticated}
                showChevronIcon={isAuthenticated}
                isBonus={!!isBonus}
                gtmId="topNav_signin"
                signInTextClassName={classnames({
                    [styles.notAuthenticated]: !account,
                    [styles.isAuthenticated]: account,
                })}
                signInIconClassName={styles.signInAvatar}
                profileAvatarUrl={account?.profileAvatarUrl}
            />
            {!hideDivider && <div className={styles.divider} />}
            {account && (
                <NavigationDropdown
                    open={isDropdownOpen}
                    onClose={handleNavigationDropdownClose}
                    fullScreen
                    className={styles.dropdownContent}
                    controlRef={signInTextRef}
                    navRef={props.navRef}
                >
                    <div className={styles.menuContent}>
                        {!isDesktop && (
                            <>
                                <CloseButton onClick={handleNavigationDropdownClose} />
                                <div className={styles.dropdownSignInHorizontalDivider} />
                                <div className={styles.dropdownSignInSection}>
                                    <div className={styles.wrapperDropdownSignIn}>
                                        <div>
                                            <SignInIcon
                                                isBonus={!!isBonus}
                                                profileAvatarUrl={account?.profileAvatarUrl}
                                            />
                                        </div>
                                        <span className={classnames(styles.signInText, styles.dropdownSignInText)}>
                                            <span>{getSignInText(account.firstName)}</span>{' '}
                                            {isShowRewardsInfo && (
                                                <span
                                                    className={classnames(
                                                        't-paragraph-small',
                                                        styles.rewardsInfoMobile
                                                    )}
                                                >
                                                    {rewardsInfo}
                                                </span>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </>
                        )}
                        {navigationLinks.map(({ link, name, count, trackingId }) => (
                            <div key={name} className={styles.itemContainer}>
                                <Link key={name} href={link}>
                                    <a
                                        key={name}
                                        className={classnames(styles.accountMenulink, {
                                            't-paragraph-small-strong': isDesktop,
                                            't-subheader-small': !isDesktop,
                                        })}
                                        tabIndex={0}
                                        onClick={pathname === link && !isDesktop && handleNavigationDropdownClose}
                                        {...(trackingId && { 'data-gtm-id': trackingId })}
                                    >
                                        {name}
                                        {!!count && (
                                            <Counter
                                                value={count}
                                                counterClassName={styles.counter}
                                                ariaLabel={`${name.toLowerCase()} counter`}
                                            />
                                        )}
                                    </a>
                                </Link>
                            </div>
                        ))}
                        <div className={styles.horizontalDivider} />
                        <LogoutButton className={styles.logoutButton} />
                    </div>
                </NavigationDropdown>
            )}
            {hasQueryEmailVerification && (
                <SuccessErrorModal
                    open={isOpenVerificationModal}
                    isSuccess={!!query?.isEmailVerification}
                    onButtonClick={query?.isEmailVerification && redirectRewardsPage}
                    onClose={onCloseEmailVerificationModal}
                    description={
                        query?.isEmailVerification
                            ? 'You are able to redeem certificates for getting free products'
                            : 'Please try again later'
                    }
                    title={query?.isEmailVerification ? `${query?.status}` : 'Account was not verified'}
                    closeButtonText={query?.isEmailVerification && 'Go to rewards roster'}
                />
            )}
        </div>
    );
};

export default SignIn;

import React, { useMemo, useState } from 'react';
import { Form, Formik } from 'formik';

import classNames from 'classnames';

import styles from './sendMeTextLinkModal.module.css';
import { validatePhoneWithMask } from '../../../../../common/helpers/complexValidateHelper';
import NotificationModal from '../../../../molecules/notificationModal';
import FormikInput from '../../../formikInput';
import Checkbox from '../../../../atoms/checkbox';
import { CheckInModalProps, CheckInModalsTypes } from '../../types';
import { IOrderModel, NotificationResponseStatusModel } from '../../../../../@generated/webExpApi';
import { InspireButton } from '../../../../atoms/button';
import { useNotifications } from '../../../../../redux/hooks';
import { NotificationType } from '../../../../../redux/notifications';
import { notificationService } from '../../../../../common/services/notificationService/notification';

interface SendMeTextLinkModalProps extends CheckInModalProps {
    order: IOrderModel;
}

const SendMeTextLinkModal = ({ modalsController, open, order }: SendMeTextLinkModalProps) => {
    const userPhone = order?.fulfillment?.contactDetails?.phone;
    const { sendSms } = notificationService();
    const {
        actions: { enqueue },
    } = useNotifications();

    const phoneNumberMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    const testPhoneField = useMemo(
        () =>
            validatePhoneWithMask('Phone number', {
                require: 'Phone number is incomplete',
                invalid: 'This phone number is invalid. Please try again.',
            }),
        []
    );
    const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);

    const handleCheckBox = () => {
        setIsCheckboxSelected(!isCheckboxSelected);
    };

    const onSubmit = (data) => {
        const errorMessage = 'We couldn’t send the text message. Please try again.';

        sendSms({ iNotificationBodyRequestModel: { number: data.phone, orderId: order.id } })
            .then((res) => {
                if (res.status === NotificationResponseStatusModel.Success) {
                    enqueue({
                        message: 'Text message sent! We look forward to your arrival!',
                        type: NotificationType.SUCCESS,
                    });
                } else {
                    enqueue({ message: errorMessage });
                }
            })
            .catch(() => {
                enqueue({ message: errorMessage });
            })
            .finally(() => {
                modalsController.close(CheckInModalsTypes.TEXT_ME_A_LINK);
            });
    };

    return (
        <NotificationModal
            open={open}
            contentClassName={classNames(styles.content, styles.modalContent)}
            titleClassName={styles.modalTitle}
            messageClassName={classNames(styles.modalMessage, 't-paragraph-small')}
            buttonsClassName={styles.modalButtons}
            title="Send Me A Text Link"
            onModalClose={() => modalsController.close(CheckInModalsTypes.TEXT_ME_A_LINK)}
            message="We’ll send you a text message with a link to check in on your phone when you get to your Sonic. After opening the link please, sign in to your account."
        >
            <Formik
                onSubmit={onSubmit}
                initialValues={{
                    phone: userPhone || '',
                }}
                validateOnMount
                enableReinitialize
            >
                {({ isValid }) => {
                    return (
                        <Form className={styles.formContainer}>
                            <FormikInput
                                name="phone"
                                label="Phone number"
                                type="tel"
                                placeholder="(XXX) XXX-XXXX"
                                mask={phoneNumberMask}
                                className={styles.inputContainer}
                                inputClassName={styles.input}
                                labelClassName={styles.inputLabel}
                                errorClassName={styles.inputError}
                                validate={testPhoneField}
                                required
                            />
                            <InspireButton
                                submit
                                disabled={!isValid || !isCheckboxSelected}
                                text="Send Text"
                                className={classNames(styles.modalButton, styles.test)}
                                fullWidth
                            />
                        </Form>
                    );
                }}
            </Formik>
            <div className={styles.contentBorder} />
            <div className={styles.checkBoxContainer}>
                <Checkbox
                    onClick={handleCheckBox}
                    className={styles.checkbox}
                    fieldName="privacyPolicy"
                    selected={isCheckboxSelected}
                    size="standard"
                />
                <label className={classNames(styles.checkBoxLabel, 't-paragraph')} htmlFor="privacyPolicy">
                    I agree to receive a one-time text message from SONIC. Not all carriers covered. Message and data
                    dates apply. Privacy Policy.
                </label>
            </div>
        </NotificationModal>
    );
};

export default SendMeTextLinkModal;

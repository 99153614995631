export enum DistanceUnits {
    MILES = 'MILES',
    FOOTS = 'FOOTS',
}

// multiplier to convert foots to miles
const MILES_FOOTS_MULTIPLIER = 5280;

/**
 * Method to convert distance between different units
 * @method convertDistance
 * @param {number} value value to convert
 * @param {DistanceUnits} fromUnit value unit
 * @param {DistanceUnits} toUnit desired unit
 * @returns {number} coverted value
 * @example
 * convertDistance(1, DistanceUnits.MILES, DistanceUnits.FOOTS); // will return 5280
 * convertDistance(5280, DistanceUnits.FOOTS, DistanceUnits.MILES); // will return 1
 */
export function convertDistance(value: number, fromUnit: DistanceUnits, toUnit: DistanceUnits): number {
    if (fromUnit === DistanceUnits.FOOTS && toUnit === DistanceUnits.MILES) {
        return value / MILES_FOOTS_MULTIPLIER;
    }

    if (fromUnit === DistanceUnits.MILES && toUnit === DistanceUnits.FOOTS) {
        return value * MILES_FOOTS_MULTIPLIER;
    }
}

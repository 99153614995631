import React from 'react';
import styles from './notification.module.css';
import classnames from 'classnames';
import Icon from '../../../atoms/BrandIcon';
import { SUCCESS_LABEL } from './constants';

interface ISuccessNotification {
    title?: string;
    description: string;
}

const SuccessNotification = ({ title, description }: ISuccessNotification) => {
    return (
        <>
            <Icon icon="action-check" size="l" className={classnames(styles.icon, styles.success)} />
            <span className={classnames('t-header-h3', styles.title)}>{title || SUCCESS_LABEL}</span>
            <p className={classnames('t-paragraph', styles.notificationDescription)}>
                <span>{description}</span>
            </p>
        </>
    );
};

export default SuccessNotification;

import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import FormikInput from '../../../formikInput';
import ModalFooter from '../../common/modalFooter';
import { InspireButton } from '../../../../atoms/button';
import styles from './typeYourStallNumber.module.css';
import classNames from 'classnames';
import CheckInModal from '../../common/modal';
import { noop } from '../../../../../common/helpers/noop';
import { CheckInModalProps, CheckInModalsTypes } from '../../types';
import { IOrderModel, TErrorCodeModel } from '../../../../../@generated/webExpApi';
import { ICheckInRequest } from '../../../../../common/hooks/useCheckInOrder';

interface TypeYourStallNumberProps extends CheckInModalProps {
    order: IOrderModel;
    checkIn: ICheckInRequest;
    loading: boolean;
}

function TypeYourStallNumberModal({ open, modalsController, checkIn, loading }: TypeYourStallNumberProps) {
    const [stallNumber, setStallNumber] = useState<string>('');
    const disabled = !stallNumber || loading;

    const onStallSubmit = (setErrors) => {
        checkIn(parseInt(stallNumber)).then(({ error }) => {
            if (error) {
                switch (error?.code) {
                    case TErrorCodeModel.StallUnavailable:
                        modalsController.push(CheckInModalsTypes.STALL_UNAVAILABLE);
                        break;
                    case TErrorCodeModel.InvalidSpotNumber:
                        setErrors({
                            stallNumber: 'You have entered an invalid stall number. Please enter a valid one.',
                        });
                        break;
                    default:
                        modalsController.push(CheckInModalsTypes.CONNECT_ISSUES);
                }
            } else {
                modalsController.set(CheckInModalsTypes.ORDER_STATUS);
            }
        });
    };

    return (
        <CheckInModal open={open} onClose={() => modalsController.set(null)}>
            <Formik initialValues={{ stallNumber: stallNumber }} onSubmit={noop}>
                {({ isValid, setErrors, validateForm }) => (
                    <div className={styles.container}>
                        <div>
                            <span className={classNames('t-subheader', styles.imhere)}>I&apos;m Here</span>
                            <h3 className={classNames('t-header-h1', styles.title)}>Check In</h3>
                        </div>
                        <div>
                            <h4 className={classNames('t-subheader', styles.stallNumberTitle)}>
                                Type Your Stall Number
                            </h4>

                            <Form>
                                <FormikInput
                                    data-testid="stall-number-input"
                                    label="Stall number"
                                    labelClassName={classNames('t-paragraph-hint', styles.inputLabel)}
                                    inputClassName={styles.input}
                                    required
                                    name="stallNumber"
                                    placeholder="XX"
                                    inputmode="numeric"
                                    maxLength={2}
                                    value={stallNumber}
                                    onChange={(e) => {
                                        const regex = /^\d*$/; // allow only numbers

                                        if (e.target.value === '') {
                                            setStallNumber('');
                                        } else if (regex.test(e.target.value)) {
                                            setStallNumber(parseInt(e.target.value).toString());
                                        }

                                        validateForm();
                                    }}
                                />
                            </Form>
                        </div>

                        <p
                            className={classNames('t-paragraph-strong', styles.link)}
                            onClick={() => modalsController.push(CheckInModalsTypes.FIND_STALL)}
                        >
                            Find Stall Number
                        </p>
                        <p
                            className={classNames('t-paragraph-strong', styles.link)}
                            onClick={() => modalsController.push(CheckInModalsTypes.INSIDE_PICK_UP)}
                        >
                            Inside Pickup?
                        </p>

                        <ModalFooter>
                            <InspireButton
                                data-testid="button"
                                disabled={!isValid || disabled}
                                onClick={() => onStallSubmit(setErrors)}
                            >
                                Check In
                            </InspireButton>
                        </ModalFooter>
                    </div>
                )}
            </Formik>
        </CheckInModal>
    );
}

export default TypeYourStallNumberModal;

import { useMemo } from 'react';
import { IUserAccountMenuFields } from '../../@generated/@types/contentful';
import { DEALS, REWARDS } from '../constants/account';
import { getAccountNavLinks } from '../helpers/accountHelper';
import { InspireCmsEntry } from '../types';
import { useConfiguration } from './useConfiguration';
import { useOrderLocation, useRewards } from '../../redux/hooks';

const useNavigationLinks = (userAccountMenu: InspireCmsEntry<IUserAccountMenuFields>) => {
    const { configuration } = useConfiguration();
    const { currentLocation } = useOrderLocation();

    const { totalCount: rewardsCount, rewardsActivityHistory, getApplicableOffers } = useRewards();

    const navigationLinks = useMemo(
        () =>
            getAccountNavLinks(userAccountMenu, rewardsActivityHistory.length, configuration).map((item) => {
                if (item.type === DEALS) {
                    return {
                        ...item,
                        count: getApplicableOffers(currentLocation?.id)?.length,
                    };
                }

                if (item.type === REWARDS) {
                    return {
                        ...item,
                        count: rewardsCount,
                    };
                }

                return item;
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [rewardsCount, rewardsActivityHistory.length]
    );

    return { navigationLinks };
};

export default useNavigationLinks;

import React, { FC } from 'react';
import PageBanner from '../../atoms/pageBanner/pageBanner';

import { TTallyErrorMessageModel } from '../../../@generated/webExpApi';
import { useUnavailableTimeSlots } from '../../../common/hooks/useUnavailableTimeSlots';

const PageBannerContainer: FC = () => {
    const { hasUnavailableTimeSlots } = useUnavailableTimeSlots();

    if (!hasUnavailableTimeSlots) return null;

    return (
        <PageBanner
            text={
                TTallyErrorMessageModel.OnlineOrderingIsTemporarilyUnavailableAtThisLocationPleaseTryCallingYourLocalSportsBarToPlaceAnOrder
            }
        />
    );
};

export default PageBannerContainer;

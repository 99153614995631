import React, { ReactEventHandler } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import {
    IDocumentLink,
    IExternalLink,
    IMenuCategoryLink,
    IPageLink,
    IPhoneNumberLink,
    IProduct,
} from '../../../../@generated/@types/contentful';
import isRelativeLink from '../../../../lib/isRelativeLink';
import { getLinkDetails } from '../../../../lib/link';
import { useGlobalProps } from '../../../../redux/hooks';

import styles from './navigationLink.module.css';

interface INavigationLinkProps {
    link: IExternalLink | IMenuCategoryLink | IPageLink | IProduct | IPhoneNumberLink | IDocumentLink;
    exact?: boolean;
    onClick?: ReactEventHandler;
    dataTestId?: string;
    inLowerCase?: boolean;
    activeClassName?: string;
    labelClassName?: string;
}

const NavigationLink: React.FC<INavigationLinkProps> = ({
    link,
    onClick,
    exact,
    dataTestId,
    inLowerCase,
    activeClassName,
    labelClassName,
}) => {
    const router = useRouter();
    const globalProps = useGlobalProps();

    if (!link.fields) return null;
    const { href, name } = getLinkDetails(link, { productDetailsPagePaths: globalProps?.productDetailsPagePaths });

    const isLinkActive = exact ? `${href}/` === router.asPath : href.split('/')[1] === router.asPath.split('/')[1];

    const linkClasses = classnames(styles.link, {
        [styles.linkActive]: isLinkActive,
        [activeClassName]: isLinkActive,
    });

    const labelClasses = classnames(styles.linkLabel, labelClassName);

    const linkName = inLowerCase ? name?.toLowerCase() : name?.toUpperCase();

    // TODO: replace with other solution
    if (isRelativeLink(href)) {
        return (
            <Link key={href} href={href}>
                <a
                    className={linkClasses}
                    onClick={onClick}
                    data-gtm-id={`main-nav-${name?.replace(/\s+/g, '-').toLowerCase()}`}
                    data-testid={dataTestId}
                >
                    <span className={labelClasses}>{linkName}</span>
                </a>
            </Link>
        );
    }
    return (
        <a
            key={href}
            href={href}
            className={styles.link}
            onClick={onClick}
            data-gtm-id={`main-nav-${name?.toLowerCase()}`}
            data-testid={dataTestId}
        >
            <span className={labelClasses}>{linkName}</span>
        </a>
    );
};

export default NavigationLink;

import React, { useCallback, useMemo, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import { INavigationFields, IUserAccountMenuFields } from '../../../@generated/@types/contentful';
import Icon from '../../atoms/BrandIcon';
import NavigationLink from './navigationLink';
import { useConfiguration, useOrderLocation, useRewards } from '../../../redux/hooks';
import NavigationMenu from './navigationMenu';
import useWindowResize from '../../../common/hooks/useWindowResize';
import getBrandInfo, { BRANDS } from '../../../lib/brandInfo';
import dynamic from 'next/dynamic';
import SignIn from './signIn';
import { useFeatureFlags } from '../../../redux/hooks/useFeatureFlags';
import {
    NAVIGATION_LINKS_BLACK_LIST,
    IS_LINKS_IN_LOWER_CASE,
    logoDesktop,
    SHOULD_HIDE_START_ORDER_ON_LOCATION_DETAILS_PAGE,
    SHOULD_SHOW_DEALS_COUNTER,
} from './constants';
import { useAppDispatch } from '../../../redux/store';
import { GTM_SEARCH_NAVIGATION_LOCATION } from '../../../common/services/gtmService/constants';
import {
    SearchLocationAction,
    SearchLocationLabelType,
    GtmSearchLocationData,
} from '../../../common/services/gtmService/types';
import { dealSignUpLink, NAV_LINKS } from './navigationLinks.constants';
import Logo from '../../atoms/Logo';
import { getImageUrl } from '../../../common/helpers/contentfulImage';
import { InspireCmsEntry } from '../../../common/types';
import { InspireButton } from '../../atoms/button';
import useStartAnOrderLink from '../../../common/hooks/useStartAnOrderLink';
import Counter from '../../atoms/counter';
import MobileNavigationLinks from './mobileNavigationLinks';
import SignUpBanner from '../../clientOnly/signUpBanner';
import { OrderLocationMethod } from '../../../redux/orderLocation';

import styles from './index.module.css';
import { useAuth0 } from '@auth0/auth0-react';
import { useCheckInOrder } from '../../../common/hooks/useCheckInOrder';
import CheckInContainer from '../checkin';
import { useScreen } from '../../../common/hooks/useScreen';

const Bag = dynamic(import('./bag'), {
    ssr: false,
    // eslint-disable-next-line react/display-name
    loading: () => null,
});

const LocationFlowDropdown = dynamic(import('../../clientOnly/locationFlowDropdown'), {
    ssr: false,
    // eslint-disable-next-line react/display-name
    loading: () => null,
});

export default function Navigation(props: {
    navigation: InspireCmsEntry<INavigationFields>;
    userAccountMenu: InspireCmsEntry<IUserAccountMenuFields>;
    titleTooltip?: string;
    showSignUpBanner?: boolean;
}): JSX.Element {
    const { navigation, titleTooltip, userAccountMenu, showSignUpBanner } = props;
    const { featureFlags } = useFeatureFlags();
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [isDropdownOpen, setDropdownToggle] = useState<boolean>(false);

    const router = useRouter();
    const navRef = useRef<HTMLElement>(null);
    const startAnOrderLink = useStartAnOrderLink();
    const {
        configuration: { isOAEnabled },
    } = useConfiguration();
    const { isAuthenticated } = useAuth0();
    const { pickupAddress, method, currentLocation } = useOrderLocation();

    const isLocationSelected = method !== OrderLocationMethod.NOT_SELECTED;

    const { brandId, brandName } = getBrandInfo();

    const { isDesktop } = useScreen(true);

    const isArbysMobile = !isDesktop && brandId === BRANDS.arbys.brandId;

    const handleMenuOpen = useCallback(() => {
        setIsMenuOpen(true);
    }, []);
    const handleMenuClose = useCallback(() => {
        setIsMenuOpen(false);
    }, []);

    useWindowResize(handleMenuClose);

    const dispatch = useAppDispatch();

    const handleToggleDropdown = () => {
        const payload = {
            action: SearchLocationAction.SEARCH,
            label: SearchLocationLabelType.NAVIGATION,
        } as GtmSearchLocationData;
        dispatch({ type: GTM_SEARCH_NAVIGATION_LOCATION, payload });
        setDropdownToggle(!isDropdownOpen);
    };

    const { totalCount, getApplicableOffers } = useRewards();

    const rewardsCount = useMemo(() => {
        if (brandId === BRANDS.bww.brandId) return totalCount;

        if (brandId === BRANDS.arbys.brandId) return getApplicableOffers(currentLocation?.id)?.length;
    }, [brandId, getApplicableOffers, currentLocation?.id, totalCount]);

    const asset = getImageUrl(navigation?.fields?.logo, 1500);

    const isLocationDetailsPage = !!router.query?.['storeId']?.length;

    const { pathname } = useRouter();
    const isHomeScreen = pathname === '/';

    const checkInData = useCheckInOrder();

    const showCheckInBtn =
        checkInData.order &&
        isHomeScreen &&
        isAuthenticated &&
        !checkInData.isOrderCheckedIn &&
        (!checkInData.isGeolocationPermissionDenied || isDesktop);

    const shouldHideStartOrderButton = SHOULD_HIDE_START_ORDER_ON_LOCATION_DETAILS_PAGE && isLocationDetailsPage;

    const showStartOrderButton = !shouldHideStartOrderButton && !isLocationSelected && !showCheckInBtn;

    return (
        <>
            <nav className={styles.navigation} data-gtm-id="main-nav" ref={navRef}>
                <div className={styles.wrapper}>
                    <Icon
                        className={`${styles.icon} ${styles.menuIcon}`}
                        icon="navigation-hamburger"
                        onClick={handleMenuOpen}
                        data-testid="navigation-hamburger"
                    />
                    <div className={styles.centerContainer}>
                        <div className={styles.logoContainer}>
                            <Link href="/">
                                <a
                                    className={styles.logo}
                                    data-gtm-id="main-nav-home"
                                    data-testid="main-nav-home"
                                    title={'Go to Homepage'}
                                >
                                    <Logo
                                        urlLogo={asset}
                                        urlLogoDesktop={logoDesktop}
                                        className={styles.logoImage}
                                        wrapperClassName={styles.logoWrapper}
                                        alt={brandName}
                                    />
                                </a>
                            </Link>
                        </div>
                        {!isArbysMobile && (
                            <div className={styles.linkContainer}>
                                {navigation?.fields?.links
                                    ?.filter(
                                        (link) =>
                                            'nameInUrl' in link.fields &&
                                            !NAVIGATION_LINKS_BLACK_LIST.includes(link.fields.nameInUrl)
                                    )
                                    ?.map((link) => {
                                        const nameInUrl = 'nameInUrl' in link.fields ? link.fields.nameInUrl : null;
                                        const isDealLink = nameInUrl === NAV_LINKS.DEALS;
                                        return (
                                            <div className={styles.navigationLinkWrapper} key={link.sys.id}>
                                                <NavigationLink
                                                    link={isDealLink && !isAuthenticated ? dealSignUpLink : link}
                                                    exact={isDealLink}
                                                    inLowerCase={IS_LINKS_IN_LOWER_CASE}
                                                    dataTestId={`navigation-link-${link.fields.name.toLowerCase()}`}
                                                />
                                                {SHOULD_SHOW_DEALS_COUNTER &&
                                                    isLocationSelected &&
                                                    rewardsCount > 0 &&
                                                    isDealLink && (
                                                        <Counter
                                                            value={rewardsCount}
                                                            dataTestId="navigation-deals-counter"
                                                        />
                                                    )}
                                            </div>
                                        );
                                    })}
                            </div>
                        )}
                        {featureFlags.account && !isArbysMobile && (
                            <SignIn
                                navRef={navRef}
                                userAccountMenu={userAccountMenu}
                                hideDivider={isLocationDetailsPage && shouldHideStartOrderButton && !isLocationSelected}
                            />
                        )}
                    </div>
                    {isArbysMobile && (
                        <div
                            className={styles.arbysMobileLocationBlock}
                            role="button"
                            onClick={handleToggleDropdown}
                            data-testid="toggle-location-dropdown"
                        >
                            <>
                                <Icon className={styles.arbysMobileMapPinIcon} size="tiny" icon="navigation-map-pin" />
                                <span className={styles.name}>
                                    <span className={classnames('truncate', styles.nameText)}>
                                        {pickupAddress?.displayName || 'Select Location'}
                                    </span>
                                </span>
                            </>
                        </div>
                    )}
                    {featureFlags.isOrderCheckInEnabled ? (
                        <CheckInContainer {...checkInData}>
                            {({ onCheckInClick }) =>
                                showCheckInBtn ? (
                                    <InspireButton
                                        className={styles.dekstopCheckInBtn}
                                        type="small"
                                        text="Check In"
                                        onClick={onCheckInClick}
                                    />
                                ) : (
                                    <LocationFlowDropdown
                                        navRef={navRef}
                                        titleTooltip={titleTooltip}
                                        setDropdownToggle={setDropdownToggle}
                                        isDropdownOpen={isDropdownOpen}
                                    />
                                )
                            }
                        </CheckInContainer>
                    ) : (
                        <LocationFlowDropdown
                            navRef={navRef}
                            titleTooltip={titleTooltip}
                            setDropdownToggle={setDropdownToggle}
                            isDropdownOpen={isDropdownOpen}
                        />
                    )}
                    <div className={styles.iconsContainer}>
                        <Bag />
                    </div>
                </div>
                {isArbysMobile && (
                    <MobileNavigationLinks
                        links={navigation?.fields?.links}
                        rewardsCount={rewardsCount}
                        isHidden={isDropdownOpen}
                    />
                )}
                {showStartOrderButton && (
                    <div className={styles.startOrderBtnWrapper}>
                        <InspireButton
                            className={styles.startOrderBtn}
                            type="small"
                            text="Start an Order"
                            link={startAnOrderLink}
                            disabled={!isOAEnabled}
                        />
                    </div>
                )}
                {featureFlags.isOrderCheckInEnabled && (
                    <CheckInContainer {...checkInData}>
                        {({ onCheckInClick }) =>
                            showCheckInBtn && (
                                <div className={styles.mobileCheckInBtnWrapper}>
                                    <InspireButton
                                        className={styles.mobileCheckInBtn}
                                        type="small"
                                        text="Check In"
                                        onClick={onCheckInClick}
                                    />
                                </div>
                            )
                        }
                    </CheckInContainer>
                )}
            </nav>
            {showSignUpBanner && <SignUpBanner />}
            <NavigationMenu
                open={isMenuOpen}
                links={navigation?.fields?.links}
                onClose={handleMenuClose}
                rewardsCount={rewardsCount}
            />
        </>
    );
}

import React from 'react';
import classNames from 'classnames';
import BrandIcon from '../../../../atoms/BrandIcon';
import { InspireButton } from '../../../../atoms/button';
import CheckInModal from '../../common/modal';
import { CheckInModalProps } from '../../types';

import styles from './orderStatus.module.css';
import ModalFooter from '../../common/modalFooter';
import { useRouter } from 'next/router';
import { IOrderModel } from '../../../../../@generated/webExpApi';

interface OrderStatusModalProps extends CheckInModalProps {
    status?: string;
    order: IOrderModel;
}

enum VisitOrderStatus {
    READY = 'READY',
    PLACED = 'PLACED',
    PREPARING = 'PREPARING',
}

/**
 * Helper method to get title, description, icon for OrderStatusModal
 * @method getModalData
 * @returns {{title: string, description: string, iconName: string}} title and description depending on isOnStoreTerritory and isOnWrongStoreTerrirory params
 */
const getModalData = (status: string): { title: string; description: string; iconName: string } => {
    if (status === VisitOrderStatus.READY) {
        return {
            iconName: 'order-status-ready',
            title: 'Order Status',
            description: 'Your order is on its way! A carhop will deliver your order to you shortly.',
        };
    }
    return {
        iconName: 'brand-new-burger',
        title: 'Order Status',
        description: 'We are preparing your order with care and will let you know when it’s ready.',
    };
};

const OrderStatusModal = ({ open, status, order, modalsController }: OrderStatusModalProps) => {
    const router = useRouter();

    const handleCloseModal = () => {
        router.replace(`/order-details?orderId=${order.idempotentId}`);
        modalsController.set(null);
    };

    const { title, description, iconName } = getModalData(status);

    return (
        <CheckInModal open={open} onClose={handleCloseModal}>
            <div className={styles.container}>
                <BrandIcon icon={iconName} size="huge" paths={9} className={styles.icon} />
                <h3 className={classNames('t-header-card-title', styles.title)}>{title}</h3>
                <p className="t-paragraph">{description}</p>
                <ModalFooter>
                    <InspireButton onClick={handleCloseModal}>Got It</InspireButton>
                </ModalFooter>
            </div>
        </CheckInModal>
    );
};

export default OrderStatusModal;

import React from 'react';

/**
 * Method for merge dynamic value and component
 * @method mergeStringAndComponent
 * @param {string} stringValue normal string having static and dynamic value, example: Hello {link}
 * @param {string} classname classname for parent <p classname: classnames('t-paragraph', styles.description),></p> example: classnames('t-paragraph', styles.description),;
 * @param {Record<string, HTMLElement>} dynamicElements value which will be replace to the static key, example: {'{link}': '<InspireButton>Click Me</InspireButton>'
 * @returns {HTMLElement} string that has been concatenated by a dynamic element, example: Hallo <InspireButton>Click Me</InspireButton>
 * @example
 * mergeDynamicValueAndComponent(Hello {link}, {'{link}': '<InspireButton>Click Me</InspireButton>')
 */

interface IMergeDynamicValueAndComponent {
    classname?: string;
    stringValue: string;
    dynamicElements: { [key: string]: JSX.Element };
}

const mergeDynamicValueAndComponent = ({
    classname = '',
    stringValue,
    dynamicElements,
}: IMergeDynamicValueAndComponent): JSX.Element => {
    const stringContent = stringValue.split(' ');
    const output = [];
    stringContent.forEach((word: string, index: number) => {
        const shouldNotSpace = stringContent.length === index + 1;
        const space = shouldNotSpace ? '' : ' ';
        if (dynamicElements[word]) {
            output.push(...[dynamicElements[word], space]);
        } else {
            output.push(...[word, space]);
        }
    });
    return <p className={classname}>{output}</p>;
};

export { mergeDynamicValueAndComponent };

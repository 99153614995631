import React, { FC } from 'react';
import Modal from '@material-ui/core/Modal';
import classnames from 'classnames';

import styles from './notificationModal.module.css';

import { InspireButton, IInspireButtonProps } from '../../atoms/button';

import Icon from '../../atoms/BrandIcon';
import { CONSTANTS } from '../../../constants/en/commons';

interface INotificationModal {
    open: boolean;
    title?: string;
    subtitle?: string;
    message?: string;
    primaryButtonProps?: IInspireButtonProps;
    secondaryButtonProps?: IInspireButtonProps;
    onModalClose?: () => void;
    titleClassName?: string;
    subtitleClassName?: string;
    messageClassName?: string;
    contentClassName?: string;
    buttonsClassName?: string;
}

const NotificationModal: FC<INotificationModal> = ({
    open,
    title,
    subtitle,
    message,
    primaryButtonProps,
    secondaryButtonProps,
    onModalClose,
    children,
    messageClassName,
    subtitleClassName,
    titleClassName,
    contentClassName,
}) => {
    const { ARE_YOUR_SURE_TEXT } = CONSTANTS;
    return (
        <Modal open={open} className={styles.modal} onClose={onModalClose}>
            <div className={classnames(styles.content, contentClassName)}>
                <Icon
                    onClick={onModalClose}
                    icon="action-close"
                    size="m"
                    className={styles.closeIcon}
                    ariaLabel="close"
                />
                <p className={classnames('t-subheader', styles.subheader, subtitleClassName)}>
                    {subtitle || ARE_YOUR_SURE_TEXT}
                </p>
                <p className={classnames(styles.header, 't-header-h1', titleClassName)}>{title}</p>
                <p className={classnames('t-paragraph', styles.message, messageClassName)}>{message}</p>
                {children}
                <div className={styles.buttons}>
                    <InspireButton className={styles.button} type="primary" {...secondaryButtonProps} />
                    <InspireButton className={styles.button} type="primary" {...primaryButtonProps} />
                </div>
            </div>
        </Modal>
    );
};

export default NotificationModal;

import { NotificationResponseModel, SendSmsRequest, WebExperienceApi } from '../../../@generated/webExpApi';
import createErrorWrapper from '../createErrorWrapper';

/**
 * Creates a service for send sms notification
 * @class NotificationService
 * @author Vitali Karpuk <VKarpuk@inspirebrands.com>
 * @added 2022-04-10
 * @version 1.0.0
 * @memberOf  NotificationService
 */

const notificationService = () => {
    const expApi = new WebExperienceApi();
    const sendSms = createErrorWrapper<NotificationResponseModel, SendSmsRequest>(
        'sendSms',
        expApi.sendSms.bind(expApi)
    );
    return { sendSms };
};

export { notificationService };

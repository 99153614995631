import React from 'react';
import Icon from '../../../../components/atoms/BrandIcon';
import classnames from 'classnames';

import styles from './signIn.module.css';

const SignInIcon: React.FC<{ isBonus: boolean; className?: string; profileAvatarUrl?: string }> = ({
    isBonus,
    className,
    profileAvatarUrl,
}) => {
    const indicator = isBonus && <div className={styles.iconDot} aria-label="indicator" />;
    return (
        <>
            {profileAvatarUrl ? (
                <div className={styles.avatarWrapper}>
                    <img src={profileAvatarUrl} className={styles.avatar} alt="avatar-image" />
                    {indicator}
                </div>
            ) : (
                <div className={styles.signInIconWrapper}>
                    <Icon
                        icon="navigation-user"
                        size="m"
                        className={classnames(styles.signInIcon, className)}
                        ariaLabel="user-icon"
                        role="img"
                    >
                        {indicator}
                    </Icon>
                </div>
            )}
        </>
    );
};

export default SignInIcon;

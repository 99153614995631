export interface CheckInModalProps {
    open: boolean;
    modalsController: {
        set: (m: CheckInModalsTypes) => void;
        push: (m: CheckInModalsTypes) => void;
        close: (m: CheckInModalsTypes) => void;
    };
}

export enum CheckInModalsTypes {
    ALMOST_THERE = 'ALMOST_THERE',
    TYPE_STALL_NUMBER = 'TYPE_STALL_NUMBER',
    TEXT_ME_A_LINK = 'TEXT_ME_A_LINK',
    PICK_UP = 'PICK_UP',
    FIND_STALL = 'FIND_STALL',
    INSIDE_PICK_UP = 'INSIDE_PICK_UP',
    STALL_UNAVAILABLE = 'STALL_UNAVAILABLE',
    CONNECT_ISSUES = 'CONNECT_ISSUES',
    ORDER_STATUS = 'ORDER_STATUS',
    MULTIPLE_ORDERS = 'MULTIPLE_ORDERS',
    LOADER = 'LOADER',
}

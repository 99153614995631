import React, { ReactNode } from 'react';

interface IPageContentWrapper {
    children: ReactNode;
    className?: string;
    ariaLabel?: string;
}

export const PageContentWrapper = ({ children, className, ariaLabel }: IPageContentWrapper): JSX.Element => (
    <div id="main-content" className={className} aria-label={ariaLabel}>
        {children}
    </div>
);

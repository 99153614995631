import React from 'react';
import ModalFooter from '../../common/modalFooter';
import { InspireButton } from '../../../../atoms/button';
import styles from './multipleOrders.module.css';
import classNames from 'classnames';
import CheckInModal from '../../common/modal';
import { CheckInModalProps, CheckInModalsTypes } from '../../types';
import BrandIcon from '../../../../atoms/BrandIcon';

function MultipleOrdersModal({ open, modalsController }: CheckInModalProps) {
    return (
        <CheckInModal open={open} onClose={() => modalsController.set(null)}>
            <div className={styles.container}>
                <span className={classNames('t-subheader t-stylized', styles.subtitle)}>We See You&apos;ve Placed</span>
                <h1 className={classNames('t-header-h1', styles.title)}>Multiple Orders!</h1>
                <div className={styles.content}>
                    <BrandIcon icon={'order-ready-to-drive'} size="huge" paths={4} className={styles.icon} />
                    <p className="t-paragraph">
                        Locate the red button on the menu board and let the SONIC® employee know about your other
                        orders, so you can receive them all at once.
                    </p>
                </div>

                <ModalFooter>
                    <InspireButton onClick={() => modalsController.set(CheckInModalsTypes.TYPE_STALL_NUMBER)}>
                        Continue to Check In
                    </InspireButton>
                </ModalFooter>
            </div>
        </CheckInModal>
    );
}

export default MultipleOrdersModal;

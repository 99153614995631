import React, { useCallback } from 'react';
import BrandLoader from '../../../../atoms/BrandLoader';
import CheckInModal from '../../common/modal';
import { CheckInModalProps, CheckInModalsTypes } from '../../types';

import styles from './loaderModal.module.css';

const LoaderModal = ({ open, modalsController }: CheckInModalProps) => {
    const handleClose = useCallback(() => {
        modalsController.close(CheckInModalsTypes.LOADER);
    }, []);

    return (
        <CheckInModal open={open} onClose={handleClose}>
            <div className={styles.container}>
                <BrandLoader />
            </div>
        </CheckInModal>
    );
};

export default LoaderModal;

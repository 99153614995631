import React, { memo } from 'react';
import AlertBanners from '../../alertBanners/alertBanners';
import Navigation from '../../navigation';
import {
    IAlertBannerFields,
    INavigationFields,
    IUserAccountMenuFields,
} from '../../../../@generated/@types/contentful';
import SkipLink from '../../../atoms/skipLink';
import { InspireCmsEntryCollection, InspireCmsEntry } from '../../../../common/types';
import PageBannerContainer from '../../pageBannerContainer/pageBannerContainer';

interface HeaderProps {
    alertBanners?: InspireCmsEntryCollection<IAlertBannerFields>;
    webNavigation?: InspireCmsEntry<INavigationFields>;
    userAccountMenu?: InspireCmsEntry<IUserAccountMenuFields>;
    isPreviewMode?: boolean;
    titleTooltip?: string;
    showSignUpBanner?: boolean;
}

function BaseHeader({
    alertBanners,
    webNavigation,
    userAccountMenu,
    isPreviewMode,
    titleTooltip,
    showSignUpBanner,
}: HeaderProps): JSX.Element {
    return (
        <>
            <SkipLink />
            <div className="sticky-top" id="sticky-top">
                {alertBanners && <AlertBanners alertBanners={alertBanners} isPreviewMode={isPreviewMode} />}
                {webNavigation && (
                    <Navigation
                        navigation={webNavigation}
                        userAccountMenu={userAccountMenu}
                        titleTooltip={titleTooltip}
                        showSignUpBanner={showSignUpBanner}
                    />
                )}
                {webNavigation && <PageBannerContainer />}
            </div>
        </>
    );
}

export default memo(BaseHeader);

import Modal, { ModalProps } from '@material-ui/core/Modal';
import classNames from 'classnames';
import React from 'react';
import BrandIcon from '../../../atoms/BrandIcon';
import styles from './modal.module.css';

interface CheckInModalProps extends ModalProps {
    onClose: () => void;
}

function CheckInModal({ children, onClose, className, ...props }: CheckInModalProps) {
    return (
        <Modal {...props} className={classNames(styles.modal, className)}>
            <div className={styles.modalContainer}>
                <div className={styles.modalHeader}>
                    <BrandIcon
                        className={styles.closeIcon}
                        icon="action-close"
                        size="s"
                        onClick={onClose}
                        role="button"
                        aria-label="close"
                    />
                </div>
                {children}
            </div>
        </Modal>
    );
}

export default CheckInModal;

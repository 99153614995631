import { differenceInDays } from '../helpers/dateTime';

const LOCAL_STORAGE_KEY = 'check-in-cache';
const TTL = 30; // cache entry lifetime in days

interface IStallNumberCacheEntry {
    idempotentId: string;
    date: Date;
    stallNumber: number;
}

interface ICheckInCache {
    stallNumberById: Record<string, IStallNumberCacheEntry>;
}

const initialState: ICheckInCache = {
    stallNumberById: {},
};

export const saveStallNumberToCache = (idempotentId: string, stallNumber: number) => {
    const cache: ICheckInCache = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || initialState;
    const savedStallNumbers = cache.stallNumberById;

    const newSavedStallNumbers = Object.values(savedStallNumbers)
        .filter(({ date }) => differenceInDays(new Date(date), new Date()) < TTL)
        .concat({ stallNumber, date: new Date(), idempotentId })
        .reduce((prev, curr) => {
            return { ...prev, [curr.idempotentId]: curr };
        }, {} as Record<string, IStallNumberCacheEntry>);

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ ...cache, stallNumberById: newSavedStallNumbers }));
};

export const getStallNumberFromCache = (idempotentId: string) => {
    const cache: ICheckInCache = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || initialState;

    return cache.stallNumberById[idempotentId]?.stallNumber;
};

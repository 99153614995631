import React from 'react';
import { setFocus } from '../../../common/helpers/setFocus';
import { CONSTANTS } from '../../../constants/en/commons';

import styles from './index.module.css';

const SkipLink = (): JSX.Element => {
    const { SKIP_TO_MAIN_CONTENT } = CONSTANTS;
    const handleClick = (e) => {
        e.preventDefault();

        const stickyTop = document.getElementById('sticky-top');
        const mainContent = document.getElementById('main-content');

        if (mainContent) {
            window.scroll({ top: mainContent.offsetTop - stickyTop?.offsetHeight });
            setFocus(mainContent, true);
        }
    };

    return (
        <a href="#main-content" className={styles.skipLink} onClick={handleClick}>
            {SKIP_TO_MAIN_CONTENT}
        </a>
    );
};

export default SkipLink;

import { useOrderLocation } from '../../redux/hooks';
import { LocationWithDetailsModel } from '../services/locationService/types';

interface IuseLocationOrderAheadAvailability {
    isLocationOrderAheadAvailable: boolean;
    location: LocationWithDetailsModel;
}

export const useLocationOrderAheadAvailability = (): IuseLocationOrderAheadAvailability => {
    const { isCurrentLocationOAAvailable, currentLocation } = useOrderLocation();

    return {
        isLocationOrderAheadAvailable: isCurrentLocationOAAvailable,
        location: currentLocation,
    };
};

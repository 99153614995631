import { useAuth0 } from '@auth0/auth0-react';
import classnames from 'classnames';
import React from 'react';
import {
    IDocumentLink,
    IExternalLink,
    IMenuCategoryLink,
    IPageLink,
    IPhoneNumberLink,
    IProduct,
} from '../../../../@generated/@types/contentful';
import { useNotifications, useOrderLocation } from '../../../../redux/hooks';
import { OrderLocationMethod } from '../../../../redux/orderLocation';
import Counter from '../../../atoms/counter';
import NavigationLink from '../navigationLink';
import { accountLink, dealSignUpLink, NAV_LINKS, signinLink } from '../navigationLinks.constants';
import { LINKS_WHITE_LIST } from './constants';
import styles from './mobileNavigationLinks.module.css';

interface IProps {
    rewardsCount: number;
    links: Array<IExternalLink | IMenuCategoryLink | IPageLink | IProduct | IPhoneNumberLink | IDocumentLink>;
    isHidden?: boolean;
}

const MobileNavigationLinks: React.FC<IProps> = ({ links, rewardsCount, isHidden }) => {
    const { loginWithRedirect, isAuthenticated } = useAuth0();
    const { method } = useOrderLocation();
    const isLocationSelected = method !== OrderLocationMethod.NOT_SELECTED;

    const {
        actions: { enqueueError },
    } = useNotifications();

    const handleSignInClick = () => {
        if (!isAuthenticated) {
            try {
                const isDealLink = window.location.pathname === '/deals/';
                loginWithRedirect({
                    appState: { target: isDealLink ? '/account/deals' : window.location.pathname },
                    isIDP: true,
                });
            } catch ({ message }) {
                enqueueError({ message });
            }
            return;
        }
    };

    return (
        <div className={classnames({ [styles.hideMobileNavigationLinks]: isHidden }, styles.container)}>
            {links
                ?.filter((link) => 'nameInUrl' in link.fields && LINKS_WHITE_LIST.includes(link.fields.nameInUrl))
                .map((link) => {
                    const nameInUrl = 'nameInUrl' in link.fields ? link.fields.nameInUrl : null;
                    const isDealLink = nameInUrl === NAV_LINKS.DEALS;
                    return (
                        <div className={styles.linkWrapper} key={link.sys.id}>
                            <NavigationLink
                                link={isDealLink && !isAuthenticated ? dealSignUpLink : link}
                                exact={isDealLink}
                                dataTestId={`mobile-navigation-link-${link.fields.name.toLowerCase()}`}
                                activeClassName={styles.linkActive}
                                labelClassName={styles.linkLabel}
                            />
                            {rewardsCount > 0 && isDealLink && isLocationSelected && (
                                <Counter value={rewardsCount} dataTestId="mobile-navigation-deals-counter" />
                            )}
                        </div>
                    );
                })}
            <NavigationLink
                exact
                link={isAuthenticated ? accountLink : signinLink}
                onClick={handleSignInClick}
                activeClassName={styles.linkActive}
                labelClassName={styles.linkLabel}
            />
        </div>
    );
};

export default MobileNavigationLinks;

import React, { useCallback } from 'react';
import styles from './unavailableStall.module.css';
import { InspireButton } from '../../../../atoms/button';
import CheckInModal from '../../common/modal';
import ModalFooter from '../../common/modalFooter';
import { CheckInModalProps, CheckInModalsTypes } from '../../types';
import classNames from 'classnames';
import BrandIcon from '../../../../atoms/BrandIcon';

function UnavailableStallModel({ open, modalsController }: CheckInModalProps) {
    const handleClose = useCallback(() => {
        modalsController.close(CheckInModalsTypes.STALL_UNAVAILABLE);
    }, []);

    return (
        <CheckInModal open={open} onClose={handleClose}>
            <div className={styles.container}>
                <div>
                    <span className={classNames('t-subheader', styles.ohno)}>Oh No...</span>
                    <h3 className={classNames('t-header-h1', styles.title)}>This Stall is Currently Unavailable</h3>
                </div>
                <BrandIcon icon={'order-find-a-location'} size="huge" paths={4} className={styles.icon} />
                <>
                    <p className="t-paragraph">Please try again later or select another stall.</p>
                </>
                <ModalFooter>
                    <InspireButton onClick={handleClose}>Got It</InspireButton>
                </ModalFooter>
            </div>
        </CheckInModal>
    );
}

export default UnavailableStallModel;

import { ILocationsPageModel, ILocationWithDetailsModel } from '../../../../../@generated/webExpApi';
import { CHECKIN_DISTANCE_LIMIT } from '../../constants';

/**
 * Helper method to return wrong store if user on it territory from location search
 * Checks distance to the store and that store is not the pickup store
 * @method getWrongStoreFromLocationResult
 * @param {ILocationsPageModel} locationsSearchResult
 * @param {string} pickupStoreId
 * @returns {ILocationWithDetailsModel} wrong store location data
 */
export const getWrongStoreFromLocationResult = (
    locationsSearchResult: ILocationsPageModel,
    pickupStoreId: string
): ILocationWithDetailsModel => {
    const store = locationsSearchResult?.locations?.[0]; // stores already sorted by the distance to them

    if (
        store &&
        store.id !== pickupStoreId &&
        Number.isFinite(store?.distance?.amount) &&
        store.distance.amount < CHECKIN_DISTANCE_LIMIT
    ) {
        return store;
    }
};

/**
 * Helper method to get title and description for almost there modal
 * @method getTitleAndDescription
 * @param {boolean} isOnStoreTerritory
 * @param {boolean} isOnWrongStoreTerritory
 * @returns {{title: string, description: string}} title and description depending on isOnStoreTerritory and isOnWrongStoreTerrirory params
 */
export const getTitleAndDescription = (
    isOnStoreTerritory: boolean,
    isOnWrongStoreTerritory: boolean
): { title: string; description: string } => {
    if (isOnStoreTerritory) {
        return {
            title: "We're Here!",
            description: 'Please park in a SONIC® stall and tap the button below to start your check-in process.',
        };
    }

    if (isOnWrongStoreTerritory) {
        return {
            title: 'Whoops!',
            description:
                "Looks like you're at the wrong location. Please drive to your selected SONIC® and return to check-in.",
        };
    }

    return { title: 'Almost There!', description: 'Seems like you are too far.' };
};

import { locationSearch, ILocationSearchResult, getCoordinates, Coordinates } from '../services/locationService';
import { useCallback, useState } from 'react';
import { LoadingStatusEnum } from '../types';

export interface IUsePickupLocationsSearch {
    searchLocationsByQuery: (query: string) => Promise<void>;
    searchLocationsByCoordinates: (coordinates: Coordinates) => Promise<void>;
    searchLocationsByUserGeolocation: () => Promise<void>;
    resetSearchResult: () => void;
    fetchMoreLocations: () => Promise<void>;
    locationsSearchResult: ILocationSearchResult;
    locationsSearchStatus: LoadingStatusEnum;
    moreLocationsFetchStatus: LoadingStatusEnum;
    isAbleToFetchMoreLocations: boolean;
}

const usePickupLocationsSearch = (): IUsePickupLocationsSearch => {
    const [locationQueryCoordinates, setLocationQueryCoordinates] = useState<Coordinates>(null);

    const [locationsSearchResult, setLocationsSearchResult] = useState<ILocationSearchResult>(null);
    const [locationsSearchStatus, setLocationsSearchStatus] = useState(LoadingStatusEnum.Idle);

    const [moreLocationsFetchStatus, setMoreLocationsFetchStatus] = useState(LoadingStatusEnum.Idle);

    const resetSearchResult = () => {
        setLocationsSearchResult(null);
        setLocationsSearchStatus(LoadingStatusEnum.Idle);
    };

    const searchLocationsByQuery = async (query: string) => {
        if (!query) {
            return;
        }

        setLocationsSearchStatus(LoadingStatusEnum.Loading);
        setLocationsSearchResult(null);

        try {
            const coordinates = await getCoordinates(query);
            setLocationQueryCoordinates(coordinates);
            await searchLocationsByCoordinates(coordinates);
        } catch {
            setLocationsSearchResult(null);
            setLocationsSearchStatus(LoadingStatusEnum.Error);
        }
    };

    const searchLocationsByCoordinates = useCallback(async (coordinates: Coordinates) => {
        if (!(coordinates && coordinates.lat && coordinates.lng)) {
            return;
        }
        setLocationsSearchStatus(LoadingStatusEnum.Loading);
        setLocationsSearchResult(null);

        try {
            const searchResult = await locationSearch(coordinates);

            setLocationsSearchResult(searchResult);
            setLocationsSearchStatus(LoadingStatusEnum.Success);
        } catch {
            setLocationsSearchResult(null);
            setLocationsSearchStatus(LoadingStatusEnum.Error);
        }
    }, []);

    const searchLocationsByUserGeolocation = useCallback(async () => {
        setLocationsSearchStatus(LoadingStatusEnum.Loading);

        return new Promise<void>((res) => {
            navigator.geolocation.getCurrentPosition(
                (location) => {
                    const { latitude, longitude } = location.coords;

                    setLocationsSearchStatus(LoadingStatusEnum.Success);
                    setLocationQueryCoordinates({ lat: latitude, lng: longitude });
                    searchLocationsByCoordinates({ lat: latitude, lng: longitude });
                    res();
                },
                () => {
                    setLocationsSearchStatus(LoadingStatusEnum.Error);
                    res();
                }
            );
        });
    }, [searchLocationsByCoordinates]);

    const isAbleToFetchMoreLocations = locationsSearchResult ? !locationsSearchResult.metadata.isLastPage : false;

    const fetchMoreLocations = async () => {
        if (!isAbleToFetchMoreLocations) {
            return;
        }

        setMoreLocationsFetchStatus(LoadingStatusEnum.Loading);

        try {
            const result = await locationSearch(
                locationQueryCoordinates,
                locationsSearchResult.metadata.pageNumber + 1
            );

            setLocationsSearchResult({
                ...result,
                locations: locationsSearchResult.locations.concat(result.locations),
            });
            setMoreLocationsFetchStatus(LoadingStatusEnum.Success);
        } catch {
            setMoreLocationsFetchStatus(LoadingStatusEnum.Error);
        }
    };

    return {
        searchLocationsByQuery,
        searchLocationsByCoordinates,
        searchLocationsByUserGeolocation,
        fetchMoreLocations,
        locationsSearchStatus,
        locationsSearchResult,
        moreLocationsFetchStatus,
        isAbleToFetchMoreLocations,
        resetSearchResult,
    };
};

export default usePickupLocationsSearch;

import { useAccount, useOrderHistory, useOrderLocation, useSubmitOrder, useTallyOrder } from '../../redux/hooks';
import {
    IError400ResponseModel,
    IError500ExternalResponseModel,
    IOrderModel,
    IVisitRegisterRequestModel,
    IVisitRegisterResponseModel,
    VisitLocationTypeModel,
} from '../../@generated/webExpApi';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isOrderCheckInEnabled } from '../../lib/getFeatureFlags';
import { isUndefined } from '../helpers/isUndefined';
import { registerVisit } from '../services/visitService';
import { getStallNumberFromCache, saveStallNumberToCache } from '../helpers/checkInLocalStorageHelper';
import { isActiveOrder } from '../helpers/orderHistory/isActiveOrder';
import { useBrazeIntegration } from './braze/useBrazeIntegration';
import { BrazeEventKey, mapForBrazeEvent } from './braze/types';

interface IUseCheckInOrderOptions {
    orderId?: string;
    order?: IOrderModel;
}

export interface IUseCheckInOrderHook {
    order?: IOrderModel;
    isGeolocationPermissionDenied?: boolean;
    checkIn: ICheckInRequest;
    checkInResult: ICheckInResult;
    isOrderCheckedIn: boolean;
    isMultipleOrdersAvailableForCheckIn: boolean;
}

interface ICheckInResult {
    stallNumber?: number;
    response?: IVisitRegisterResponseModel;
    error?: IError500ExternalResponseModel | IError400ResponseModel;
    loading: boolean;
}

export type ICheckInRequest = (stall: number) => Promise<ICheckInResult>;

/**
 * Hook that contains logic related to Sonic Check in
 * @method useCheckInOrder
 * @param {string} orderId if passed tries to find order that is available for checkin in order history or in last submit order
 * @param {IOrderModel} order if passed uses this order for checkin logic otherwise returns first order that can be checked in from order history
 * @returns {IUseCheckInOrderHook} returns checkin order data
 */
export const useCheckInOrder = ({ orderId, order }: IUseCheckInOrderOptions = {}): IUseCheckInOrderHook => {
    const { orderHistory } = useOrderHistory();
    const { account } = useAccount();
    const { lastOrder, lastRequest } = useSubmitOrder();
    const { currentLocation: lastOrderLocation } = useOrderLocation();
    const { tallyOrder } = useTallyOrder();
    const [checkInOrder, setCheckInOrder] = useState<IOrderModel>(null);
    const [isGeolocationPermissionDenied, setIsGeolocationPermissionDenied] = useState(false);
    const [checkInResult, setCheckInResult] = useState<ICheckInResult>({
        loading: false,
        response: null,
        error: null,
        stallNumber: null,
    });
    const { isBrazeAvailable, setPurchaseEvent, setCustomEvent } = useBrazeIntegration();

    // checks if user has multiple orders available for checkin in the same location as current checkin order location
    const isMultipleOrdersAvailableForCheckIn = useMemo(() => {
        if (checkInOrder) {
            const checkInOrderLocationId = checkInOrder.fulfillment?.location?.id;
            const checkInOrderId = checkInOrder.idempotentId;

            const ordersAvailableForCheckIn = orderHistory?.filter(
                (order) =>
                    order.idempotentId !== checkInOrderId &&
                    order.fulfillment?.location?.id === checkInOrderLocationId &&
                    isActiveOrder(order) &&
                    !getStallNumberFromCache(order.idempotentId)
            );

            return !!ordersAvailableForCheckIn?.length;
        }

        return false;
    }, [checkInOrder, orderHistory]);

    function handlePermission() {
        navigator.permissions?.query({ name: 'geolocation' }).then((result) => {
            if (result.state === 'denied') {
                setIsGeolocationPermissionDenied(true);
            }
        });
    }

    useEffect(() => {
        if (checkInOrder) {
            const savedStallNumber = getStallNumberFromCache(checkInOrder.idempotentId);
            if (savedStallNumber && savedStallNumber !== checkInResult.stallNumber) {
                setCheckInResult({
                    ...checkInResult,
                    stallNumber: savedStallNumber,
                });
            }
        }
    }, [checkInOrder, checkInResult]);

    useEffect(() => {
        if (!isOrderCheckInEnabled()) return;

        let checkInOrder: IOrderModel;

        if (!isUndefined(order)) {
            if (isActiveOrder(order)) {
                checkInOrder = order;
            }
        } else if (orderId) {
            checkInOrder = orderHistory.find((item) => item.id === orderId && isActiveOrder(item));
        } else {
            // case for checkin btn on home page
            // search for first order from order history that can be checked in and haven't been checked in previously
            checkInOrder = orderHistory.find(
                (item) => isActiveOrder(item) && !getStallNumberFromCache(item.idempotentId)
            );
        }

        // check last order if unable to find order in history
        const hasLastOrder = lastOrder && (orderId ? orderId === lastOrder.orderId : true);
        if (!checkInOrder && hasLastOrder) {
            const mapLastOrderData = (): IOrderModel => {
                return {
                    id: lastOrder.orderId,
                    idempotentId: lastOrder.idempotentId,
                    paymentMethods: lastRequest?.orderRequestModel?.payments,
                    fulfillment: {
                        location: lastOrderLocation,
                        contactDetails: {
                            phone: lastRequest.orderRequestModel.orderData.phoneNumber,
                        },
                    },
                    total: tallyOrder?.total,
                };
            };

            checkInOrder = mapLastOrderData();
        }

        handlePermission();
        setCheckInOrder(checkInOrder);
    }, [orderId, orderHistory, lastOrder, lastRequest, lastOrderLocation, order, tallyOrder?.total]);

    const checkIn: ICheckInRequest = useCallback(
        async (stallNumber: number) => {
            const request: IVisitRegisterRequestModel = {
                order: {
                    idempotentId: checkInOrder.idempotentId,
                },
                fullfillment: {
                    visit: {
                        time: new Date(),
                        locationIdentifier: stallNumber,
                        locationType: VisitLocationTypeModel.Stall,
                    },
                },
                customer: {
                    id: account?.idpCustomerId,
                    firstName: account?.firstName,
                    lastName: account?.lastName,
                    profileAvatarUrl: account?.profileAvatarUrl,
                },
                locationId: checkInOrder.fulfillment?.location?.id,
            };

            let result: ICheckInResult;
            try {
                setCheckInResult({ ...checkInResult, loading: true, response: null, error: null });

                const response = await registerVisit(request);

                result = { response, stallNumber, error: null, loading: false };

                saveStallNumberToCache(checkInOrder.idempotentId, stallNumber);

                if (isBrazeAvailable) {
                    setCustomEvent(BrazeEventKey.ORDER_AHEAD_PICKUP);

                    checkInOrder?.products?.forEach(({ productId, price, quantity, description }) => {
                        setPurchaseEvent({
                            productId,
                            price,
                            currencyCode: 'USD',
                            quantity,
                            properties: {
                                description: description || 'unknown',
                                order_ahead: true,
                            },
                        });
                    });

                    setCustomEvent(BrazeEventKey.ORDER_AHEAD_PURCHASE, {
                        total: checkInOrder?.total,
                    });

                    const lastPaymentMethod =
                        checkInOrder?.paymentMethods?.length > 0 && checkInOrder.paymentMethods[0]?.type;

                    if (lastPaymentMethod) {
                        const paymentMethod = mapForBrazeEvent[lastPaymentMethod];
                        paymentMethod && setCustomEvent(paymentMethod);
                    }

                    const lastTip = checkInOrder?.storeTip;

                    if (lastTip) {
                        setCustomEvent(BrazeEventKey.TIP, {
                            tipAmount: lastTip,
                        });
                    }
                }
            } catch (error) {
                result = { ...checkInResult, loading: false, error };
            }

            setCheckInResult(result);
            return result;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            account?.firstName,
            account?.idpCustomerId,
            account?.lastName,
            account?.profileAvatarUrl,
            checkInResult,
            checkInOrder,
        ]
    );

    return {
        order: checkInOrder,
        isGeolocationPermissionDenied,
        checkInResult,
        checkIn,
        isOrderCheckedIn: !!checkInResult?.stallNumber,
        isMultipleOrdersAvailableForCheckIn,
    };
};

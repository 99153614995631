import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getHaversineDistance } from '../../../lib/locations';
import { CheckInModalsTypes } from './types';
import { CACHE_VALIDITY_IN_SECONDS, CHECKIN_DISTANCE_LIMIT } from './constants';
import { IUseCheckInOrderHook } from '../../../common/hooks/useCheckInOrder';
import {
    AlmostThereModal,
    SendMeTextLinkModal,
    PickUpModal,
    InsidePickupModal,
    FindStallModal,
    TypeYourStallNumberModal,
    UnavailableStallModal,
    ConnectionIssuesModal,
    OrderStatusModal,
    MultipleOrdersModal,
    LoaderModal,
} from './modals';
import { useAppDispatch } from '../../../redux/store';
import { GTM_CHECK_IN_CLICK } from '../../../common/services/gtmService/constants';
import { getUserGeoPositionFromCache } from '../../../common/helpers/userGeoPositionCache';
import { differenceInSeconds } from '../../../common/helpers/dateTime';
import { useScreen } from '../../../common/hooks/useScreen';

interface CheckInContainerProps extends IUseCheckInOrderHook {
    children: (opts: { onCheckInClick: () => void; onTextMeALinkClick: () => void }) => JSX.Element;
}

function CheckInContainer({
    children,
    order,
    checkIn,
    checkInResult,
    isMultipleOrdersAvailableForCheckIn,
}: CheckInContainerProps) {
    const [openedModals, setOpenedModals] = useState([]);
    const { isDesktop } = useScreen();
    const dispatch = useAppDispatch();

    const checkinOrder = order;
    const location = checkinOrder?.fulfillment?.location;

    useEffect(() => {
        setOpenedModals([]);
    }, [isDesktop]);

    const modalsController = useMemo(
        () => ({
            push: (modal: CheckInModalsTypes) => {
                setOpenedModals([...openedModals, modal]);
            },
            set: (modal: CheckInModalsTypes) => {
                if (!modal) {
                    setOpenedModals([]);
                } else {
                    setOpenedModals([modal]);
                }
            },
            close: (modal: CheckInModalsTypes) => {
                setOpenedModals(openedModals.filter((m) => m != modal));
            },
        }),
        [openedModals]
    );

    const handleGoToTypeYourStallNumberModal = useCallback(() => {
        if (isMultipleOrdersAvailableForCheckIn) {
            modalsController.set(CheckInModalsTypes.MULTIPLE_ORDERS);
        } else {
            modalsController.set(CheckInModalsTypes.TYPE_STALL_NUMBER);
        }
    }, [isMultipleOrdersAvailableForCheckIn, modalsController]);

    const onCheckInClick = useCallback(() => {
        const handleCoords = (latitude: number, longitude: number) => {
            const distance = getHaversineDistance(
                location?.details?.latitude,
                location?.details?.longitude,
                latitude,
                longitude
            );

            if (Number.isFinite(distance) && distance <= CHECKIN_DISTANCE_LIMIT) {
                handleGoToTypeYourStallNumberModal();
            } else {
                modalsController.set(CheckInModalsTypes.ALMOST_THERE);
            }
        };

        if (isDesktop) {
            modalsController.set(CheckInModalsTypes.TEXT_ME_A_LINK);
        } else {
            modalsController.set(CheckInModalsTypes.LOADER);
            const coordsFromCache = getUserGeoPositionFromCache();

            // using fresh cached user position if present to avoid extra call to geolocation api
            if (coordsFromCache && differenceInSeconds(coordsFromCache.date, new Date()) < CACHE_VALIDITY_IN_SECONDS) {
                handleCoords(coordsFromCache.latitude, coordsFromCache.longitude);
            } else {
                navigator.geolocation.getCurrentPosition(
                    ({ coords: { latitude, longitude } }) => {
                        handleCoords(latitude, longitude);
                    },
                    (err) => {
                        // chromium browsers sometimes return timeout error when we request geoposition multiple times on the page
                        // in that case retrieve last saved position from cache
                        if (err.code === err.TIMEOUT) {
                            handleCoords(coordsFromCache?.latitude, coordsFromCache?.longitude);
                        } else {
                            modalsController.set(CheckInModalsTypes.PICK_UP);
                        }
                    },
                    { timeout: 5000, enableHighAccuracy: true }
                );
            }
        }
        dispatch({
            type: GTM_CHECK_IN_CLICK,
            payload: 'Check In',
        });
    }, [
        isDesktop,
        location?.details?.latitude,
        location?.details?.longitude,
        modalsController,
        dispatch,
        handleGoToTypeYourStallNumberModal,
    ]);

    const onTextMeALinkClick = useCallback(() => {
        modalsController.set(CheckInModalsTypes.TEXT_ME_A_LINK);
    }, [modalsController]);

    const renderModals = () => {
        return (
            <>
                {openedModals.map((m) => {
                    const commonProps = { open: true, modalsController, key: m };
                    switch (m) {
                        case CheckInModalsTypes.LOADER:
                            return <LoaderModal {...commonProps} />;
                        case CheckInModalsTypes.ALMOST_THERE:
                            return (
                                <AlmostThereModal
                                    {...commonProps}
                                    location={location}
                                    onCheckInClick={handleGoToTypeYourStallNumberModal}
                                    checkInOrderId={checkinOrder?.id}
                                />
                            );
                        case CheckInModalsTypes.TYPE_STALL_NUMBER:
                            return (
                                <TypeYourStallNumberModal
                                    {...commonProps}
                                    order={checkinOrder}
                                    checkIn={checkIn}
                                    loading={checkInResult?.loading}
                                />
                            );
                        case CheckInModalsTypes.TEXT_ME_A_LINK:
                            return <SendMeTextLinkModal {...commonProps} order={checkinOrder} />;
                        case CheckInModalsTypes.PICK_UP:
                            return <PickUpModal {...commonProps} order={checkinOrder} />;
                        case CheckInModalsTypes.FIND_STALL:
                            return <FindStallModal {...commonProps} />;
                        case CheckInModalsTypes.INSIDE_PICK_UP:
                            return <InsidePickupModal {...commonProps} />;
                        case CheckInModalsTypes.STALL_UNAVAILABLE:
                            return <UnavailableStallModal {...commonProps} />;
                        case CheckInModalsTypes.CONNECT_ISSUES:
                            return <ConnectionIssuesModal {...commonProps} />;
                        case CheckInModalsTypes.ORDER_STATUS:
                            return (
                                <OrderStatusModal
                                    {...commonProps}
                                    status={checkInResult?.response?.order?.status}
                                    order={order}
                                />
                            );
                        case CheckInModalsTypes.MULTIPLE_ORDERS:
                            return <MultipleOrdersModal {...commonProps} />;
                    }
                })}
            </>
        );
    };

    return (
        <>
            {children({
                onCheckInClick,
                onTextMeALinkClick,
            })}
            {renderModals()}
        </>
    );
}

export default CheckInContainer;

import React from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { IOrderModel } from '../../../../../@generated/webExpApi';
import BrandIcon from '../../../../atoms/BrandIcon';
import { InspireButton } from '../../../../atoms/button';
import CheckInModal from '../../common/modal';
import { CheckInModalProps } from '../../types';

import styles from './pickUpModal.module.css';

interface PickUpModalProps extends CheckInModalProps {
    order: IOrderModel;
}

const PickUpModal = ({ open, order, modalsController }: PickUpModalProps) => {
    const router = useRouter();
    const idempotentId = order.idempotentId;
    const handleCloseModal = () => {
        router.push(`/order-details?orderId=${idempotentId}`);
        modalsController.set(null);
    };

    return (
        <CheckInModal open={open} onClose={handleCloseModal}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <BrandIcon icon="order-find-a-location" size="huge" paths={4} className={styles.icon} />
                    <h3 className={classNames('t-header-card-title', styles.title)}>Pick Up</h3>
                    <p className="t-paragraph">Your pickup instructions have changed.</p>
                </div>
                <InspireButton className={styles.btn} onClick={handleCloseModal}>
                    Got It
                </InspireButton>
            </div>
        </CheckInModal>
    );
};

export default PickUpModal;

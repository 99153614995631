import React, { useCallback } from 'react';
import styles from './insidePickup.module.css';
import { InspireButton } from '../../../../atoms/button';
import CheckInModal from '../../common/modal';
import ModalFooter from '../../common/modalFooter';
import { CheckInModalProps, CheckInModalsTypes } from '../../types';
import classNames from 'classnames';
import BrandIcon from '../../../../atoms/BrandIcon';

function InsidePickupModal({ open, modalsController }: CheckInModalProps) {
    const handleClose = useCallback(() => {
        modalsController.close(CheckInModalsTypes.INSIDE_PICK_UP);
    }, []);

    return (
        <CheckInModal open={open} onClose={handleClose}>
            <div className={styles.container}>
                <BrandIcon icon={'order-find-a-location'} size="huge" paths={4} className={styles.icon} />
                <h3 className={classNames('t-header-card-title', styles.title)}>Inside Pickup?</h3>
                <p className="t-paragraph">
                    Give the Crew Member your name at the counter and they will deliver your order.
                </p>
                <ModalFooter>
                    <InspireButton onClick={handleClose}>Got It</InspireButton>
                </ModalFooter>
            </div>
        </CheckInModal>
    );
}

export default InsidePickupModal;
